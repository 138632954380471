import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData'
import MultipleBar from '../../components/MultipleBar'

const DesempDocentesPeriodo = () => {

    const [Periodo, setPeriodo] = useState(0)
    const [PeriodoActual, setPeriodoActual] = useState(0)
    const [ListadoDocentes, setListadoDocentes] = useState([])
    const [ListadoAsigDocente, setListadoAsigDocente] = useState([])  
    const [DatosDocente, setDatosDocente] = useState([])  
    const [Loading, setLoading] = useState(false)  

    useEffect(() => {
        const fetchData = async () => {
            try {
               
                const periodoResult = await GetData('periodos/actual/', null);
                
    
                setPeriodo(periodoResult.periodo);
                setPeriodoActual(periodoResult.periodo);
               
               
            } catch (error) {
                console.error('Error al obtener los datos:', error);
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const desempenioResult = await GetData('estadisticas/desempenioadocentesperiodo/' + Periodo, null);
                setListadoDocentes(desempenioResult.docentes);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener los datos:', error);
                setLoading(false);
            }
        };

        fetchData();
    
    }, [Periodo])
    

    const DatosEstadisticoDocente = (id_docente, id_asignatura) => {
        
        setListadoAsigDocente([])
        setDatosDocente([])
        GetData('estadisticas/desempenioasignaturaperiodo/'+id_docente+'/'+id_asignatura+'/'+Periodo, null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setListadoAsigDocente(result.asignaturas)
                setDatosDocente(result.docentes)

                const modal = document.getElementById('modalD');

                    // Verificar si el modal existe antes de intentar abrirlo
                    if (modal) {
                    // Usar el método modal() para abrir el modal
                    const modalInstancia = new window.bootstrap.Modal(modal);
                    modalInstancia.show();
                    }
            }
        })

    }

  return (
    <>
     <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Estadísticas de Docentes por Período</div>                    
                    <div className="card-body pb-0">
                        
                    <div className='row mt-3'>
                            
                            <div className='col-md-2' >                            
                                    <strong>Período: </strong>
                                    <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Periodo} onChange={(e) => setPeriodo(e.target.value)}>
                                    {Array.from({length: PeriodoActual}, (_, index) => index + 1).map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-md-3 mt-4'>
                                <span className='ml-4 label'>N° Docentes: </span> <span className='texto'>{ListadoDocentes.length}</span>   
                            </div>        
                        </div>

                        <div className='alert alert-info alert-dismissible fade show mt-2'>
                            <i class="bi bi-info-circle me-1"></i> Esta tabla presenta un análisis detallado del desempeño de los docentes en diferentes áreas de enseñanza. Cada fila representa a un docente específico, y las columnas muestran el porcentaje con respecto a la notas de estudiantes en cada nivel de desempeño: Bajo, Básico, Alto y Superior.
                        </div>

                        {Loading && 
                        <div className="progress mt-1 mb-2">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }      
                        <div className='row mt-2 cnotas'>
                            <div className='list-group'>
                                {ListadoDocentes.map((doc, index) =>
                                <button key={doc.id_docente}  type="button" class="list-group-item list-group-item-action">
                                        <div className='row'>
                                            <div className='col-md-6 '>                                              
                                                <div className='nomdocente' ><a  href='#' onClick={ ()=> DatosEstadisticoDocente(doc.id_docente, 0) } data='Ver Desempeños de las asignaturas' > {doc.docente} </a></div>
                                            </div>
                                            <div className='col-md-6'> 
                                                <MultipleBar bajo={doc.procbajo} basico={doc.procbasico} alto={doc.procalto} superior={doc.procsuperior} />
                                            </div>
                                        </div>
                                </button>
                            )}
                            </div>
                           
                        </div>
                    </div>
                </div>
    </section>        

    <div className="modal fade" id="modalD" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-bar-chart-line"></i> Estadistica del Docente</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='row ml-2'>
                            <div className='col-md-12'>
                            <strong>Docente:</strong> <span className='nomagrado'>{DatosDocente}</span> <strong>Asignaturas: </strong> 
                            <span className='nomagrado'>{ListadoAsigDocente.length}</span>
                           </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='list-group'>
                                {ListadoAsigDocente.map((asig, index) =>
                                <button key={asig.asignatura} type="button" class="list-group-item list-group-item-action">
                                            <div className='row'>
                                                <div className='col-md-6 nomasignatura'>
                                                    {asig.asignatura}
                                                    <div className='nomdocente'>Grado: {asig.nombre_grado} - 0{asig.id_grupo}</div>
                                                </div>
                                                <div className='col-md-6'> <MultipleBar bajo={asig.bajo} basico={asig.basico} alto={asig.alto} superior={asig.superior} /></div>
                                            </div>
                                    </button>
                                    )}
                                </div>
                            </div>
                    </div>
                </div>
        </div>    
    </div>
    </>
  )
}

export default DesempDocentesPeriodo