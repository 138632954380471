import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData';
import MultipleBar from '../../components/MultipleBar';

const EstadisticaGradoPeriodo = () => {
    const [DatosGrado, setDatosGrado] = useState([])  
    const [DatosDocente, setDatosDocente] = useState([])  
    const [DesempGrado, setDesempGrado] = useState([])  
    const [ListadoAsignaturas, setListadoAsignaturas] = useState([])  
    const [ListadoAsigDocente, setListadoAsigDocente] = useState([])  
    const [ListaAlumnos, setListaAlumnos] = useState([])  
    const [Periodo, setPeriodo] = useState(0)  
    const [PeriodoActual, setPeriodoActual] = useState(0)  
    const [Grado, setGrado] = useState(null)
    const [Grupo, setGrupo] = useState(null)    
    const [AsignaturaSel, setAsignaturaSel] = useState(null)    
    const [ListaGados, setListaGados] = useState([])
    const [ListaGrupos, setListaGrupos] = useState([])

    useEffect(() => {

            GetData('grados/todos', null).then ((result) =>{
                let responseJSON = result;
        
                if (responseJSON.error === false){
                    setListaGados(result.grados)
                }
            })
            GetData('grupos/todos', null).then ((result) =>{
                let responseJSON = result;
        
                if (responseJSON.error === false){
                    setListaGrupos(result.grupos)
                }
            })

            GetData('periodos/actual/', null).then ((result) =>{
                let responseJSON = result;

                if (responseJSON.error === false){
                    setPeriodoActual(result.periodo)
                    setPeriodo(result.periodo)
                }
            })

            
            
    }, [])

    const buscarDatosGrado = () => {

        const gradogrupo = Grado + '-' + Grupo;
        setListadoAsignaturas([])
        setDesempGrado([])
        GetData('estadisticas/porcdesempperiodogrado/'+gradogrupo+'/'+Periodo, null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setDatosGrado(result.grado[0])
                setDesempGrado(result.grado[0].desempenios)
                
                setListadoAsignaturas(result.asignaturas)
            }
        })

    }

    const DatosEstadisticoDocente = (id_docente, id_asignatura) => {

        GetData('estadisticas/desempenioasignaturaperiodo/'+id_docente+'/'+id_asignatura+'/'+Periodo, null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setListadoAsigDocente(result.asignaturas)
                setDatosDocente(result.docentes)

                const modal = document.getElementById('modalD');

                    // Verificar si el modal existe antes de intentar abrirlo
                    if (modal) {
                    // Usar el método modal() para abrir el modal
                    const modalInstancia = new window.bootstrap.Modal(modal);
                    modalInstancia.show();
                    }
            }
        })

    }

    const ConsultarNotasAlumnos = (id_asignatura, asignatura) => {

        setAsignaturaSel('')
        GetData('estadisticas/notasalumnosasignatura/'+Grado+'/'+Grupo+'/'+ id_asignatura + '/'+Periodo, null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setListaAlumnos(result.alumnos)
                setAsignaturaSel(asignatura)
                const modal = document.getElementById('modalAlumnos');

                    // Verificar si el modal existe antes de intentar abrirlo
                    if (modal) {
                    // Usar el método modal() para abrir el modal
                    const modalInstancia = new window.bootstrap.Modal(modal);
                    modalInstancia.show();
                    }
            }
        })

    }
    

  return (
    <>
     <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Estadísticas de Desempeño por Grado</div>                    
                    <div className="card-body pb-0">
                        
                    <div className='row mt-3'>
                            <div className='col-md-2'>
                                <strong>Grado: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grado} onChange={(e) => setGrado(e.target.value)} >
                                <option defaultValue={null}>Seleccionar</option>
                                {ListaGados.map(tipo => {
                                            return <option key={tipo.id_grados} value={tipo.id_grados}>{tipo.nombre_grado}</option>
                                    })}
                                </select> 
                            </div>                               
                            <div className='col-md-2'>
                                        <strong>Grupo: </strong>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grupo} onChange={(e) => setGrupo(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGrupos.map(tipo => {
                                                    return <option key={tipo.id_grupo} value={tipo.id_grupo}>{tipo.num_grupo}</option>
                                            })}
                                        </select> 
                                      
                            </div>   
                            <div className='col-md-2'>
                                    <strong>Período: </strong>
                                    <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Periodo} onChange={(e) => setPeriodo(e.target.value)}>
                                    {Array.from({length: PeriodoActual}, (_, index) => index + 1).map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>

                            <div className='col-md-1'>
                                <button type="button" className="btn btn-info btn-sm mt-4" onClick={buscarDatosGrado} disabled={Grado===null}>
                                        <i className="bi bi-search"></i>
                                </button>                             
                            </div>  
                            <div className='col-md-3' style={{marginTop: '25px'}}>
                                <span className='ml-4 label'>Grado:</span> <span className='texto'>{DatosGrado.nombre_grado} - 0{DatosGrado.grupo}</span>  
                                <span className='ml-4 label'>N° Asig.: </span> <span className='texto'>{ListadoAsignaturas.length} </span>                                
                            </div>

                        </div>

                        <div className='alert alert-info alert-dismissible fade show mt-2'>
                            <i class="bi bi-info-circle me-1"></i> El gráfico de barras muestra el porcentaje de desempeño académico por grado y período. Cada barra representa un grado y grupo específico, con los colores indicando el porcentaje de estudiantes en cada categoría de desempeño: rojo para 'Bajo', amarillo para 'Básico', verde para 'Alto' y azul para 'Superior'. Este análisis proporciona una visión general del rendimiento académico de los estudiantes a lo largo de los períodos escolares.
                        </div>



                        <div className='row'>
                            <div className='col-md-4 nomagrado'>
                                Grado: {DatosGrado.nombre_grado} {DatosGrado.grupo} 
                            </div>
                            <div className='col-md-6'>

                                    <MultipleBar bajo={DesempGrado.bajo} basico={DesempGrado.basico} alto={DesempGrado.alto} superior={DesempGrado.superior} />

                            </div>
                        </div>

                        <div className='row mt-2 cnotas'>
                            <div className='list-group'>
                                {ListadoAsignaturas.map((asig, index) =>
                                <button key={asig.asignatura}  type="button" class="list-group-item list-group-item-action">
                                        <div className='row'>
                                            <div className='col-md-6 '>
                                                <div className='nomasignatura'>
                                                        <a href='#' data='Ver Notas' onClick={ ()=> ConsultarNotasAlumnos(asig.id_asignatura, asig.asignatura) }>{asig.asignatura} </a>
                                                </div>
                                                <div className='nomdocente' >Docente: <a  href='#' data='Ver Desempeños de las asignaturas' onClick={ ()=> DatosEstadisticoDocente(asig.id_docente, asig.id_asignatura) }> {asig.docente} </a></div>
                                            </div>
                                            <div className='col-md-6'> <MultipleBar bajo={asig.bajo} basico={asig.basico} alto={asig.alto} superior={asig.superior} /></div>
                                        </div>
                                </button>
                            )}
                            </div>
                           
                        </div>
                    </div>
                </div>
    </section>        


    <div className="modal fade" id="modalD" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-bar-chart-line"></i> Estadistica del Docente</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='row ml-2'>
                            <div className='col-md-12'>
                            <strong>Docente:</strong> <span className='nomagrado'>{DatosDocente}</span> <strong>Asignaturas: </strong> 
                            <span className='nomagrado'>{ListadoAsigDocente.length}</span>
                           </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='list-group'>
                                {ListadoAsigDocente.map((asig, index) =>
                                <button key={asig.asignatura} type="button" class="list-group-item list-group-item-action">
                                            <div className='row'>
                                                <div className='col-md-6 nomasignatura'>
                                                    {asig.asignatura}
                                                    <div className='nomdocente'>Grado: {asig.nombre_grado} - 0{asig.id_grupo}</div>
                                                </div>
                                                <div className='col-md-6'> <MultipleBar bajo={asig.bajo} basico={asig.basico} alto={asig.alto} superior={asig.superior} /></div>
                                            </div>
                                    </button>
                                    )}
                                </div>
                            </div>
                    </div>
                </div>
        </div>    
    </div>

    <div className="modal fade" id="modalAlumnos" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-bar-chart-line"></i> Notas Asignatura Grado: {DatosGrado.nombre_grado} - 0{DatosGrado.grupo}  </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body " >
                        <div className='row ml-2'>
                            <div className='col-md-12'>
                            <strong>Asignatura: </strong> <span className='nomagrado mr-2'>{AsignaturaSel} </span>  
                            <strong className='ml-3'>N° Alumnos: </strong><span className='nomagrado'>{ListaAlumnos.length}</span>
                           </div>
                        </div>
                        <div className='row mt-3 altofijomodal'>

                        <table className="table table-striped table-hover"  >
                        <thead>
                        <tr>
                            <th scope="col">N° Matri.</th>
                            <th scope="col">Nombre del Alumnos</th>
                            <th scope="col">Nota</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaAlumnos.map((us, index) => 
                            <tr key={us.id_matricula}>
                                <td>{us.id_matricula}</td>
                                <td>{us.nombres_apellidos_al}</td>                                                            
                                <td key={index} style={{ textAlign: 'center' }}>
                                     {us.nota_fn < 3 ? <span className='nota_perdida'>{us.nota_fn}</span> : us.nota_fn}
                                    </td>
                            </tr>
                        )}    
                        
                        </tbody>
                    </table>          

                        </div>
                    </div>
                </div>
        </div>    
    </div>
    </>
  )
}

export default EstadisticaGradoPeriodo