import React, { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../SessionContext';

export const InputNotaIndicador2 = ({ num_nota, id_matricula, id_indicador, nota, grado, grupo, idasignatura, onChangeNotaAct }) => {
  const [Nota, setNota] = useState(nota);
  const { validarNotasStorage } = useContext(SessionContext);
  const [modificado, setModificado] = useState(false); // Estado para controlar si la nota ha sido modificada

  useEffect(() => {
     consultarLocalStorage();
  }, [nota])
  

  const onChangeNota = (e) => {
      const inputValue = e.target.value;
      const isValidNumber = /^(?:[0-5](?:\.\d*)?|\.)$/.test(inputValue) || inputValue === '';

      if (isValidNumber || e.key === '.' || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          setNota(inputValue);
          
         
              guardaLocalStorare(inputValue, num_nota);
              onChangeNotaAct(num_nota, inputValue)

          
      } else {
          e.preventDefault();
      }
  };



  const handleFocus = (e) => {
      e.target.select();
  };

  const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
          e.preventDefault(); // Evita que se envíe el formulario por defecto
          const inputValue = parseFloat(e.target.value);
          e.target.value = inputValue.toFixed(1);

          const form = e.target.form;
          const index = Array.prototype.indexOf.call(form, e.target);
          if (index < form.elements.length - 1) form.elements[index + 1].focus();
      }
  };

  const guardaLocalStorare = (nota, num_nota) => {
    const datosGuardadosJSON = localStorage.getItem('notasGuardadas');
    let datosGuardados = [];
    
    if (datosGuardadosJSON) {
        datosGuardados = JSON.parse(datosGuardadosJSON);
    }
    
    // Buscar si ya existe un registro para el id_matricula e idasignatura
    const index = datosGuardados.findIndex(
        (item) => item.id_matricula === id_matricula && item.idasginatura === idasignatura
    );
    
    // Si ya existe, actualizar la nota correspondiente
    if (index !== -1) {
        datosGuardados[index] = {
            ...datosGuardados[index],
            [`nota${num_nota}`]: nota,  // Actualizar la nota correcta
        };
    } else {
        // Si no existe, crear un nuevo registro
        const nuevoItem = {
            id_matricula: id_matricula,
            id_grado: grado,
            id_grupo: grupo,
            idasginatura: idasignatura,
            [`nota${num_nota}`]: nota,  // Asignar la nota correcta
        };
        datosGuardados.push(nuevoItem);
    }
    
    // Guardar de nuevo en localStorage
    localStorage.setItem('notasGuardadas', JSON.stringify(datosGuardados));
    
    // Validar las notas almacenadas
    validarNotasStorage(grado, grupo, idasignatura);
};


  const consultarLocalStorage = () => {
    // Obtener datos guardados del localStorage
    const datosGuardadosJSON = localStorage.getItem('notasGuardadas');
    if (datosGuardadosJSON) {
        const datosGuardados = JSON.parse(datosGuardadosJSON);
        // Buscar si existe una nota guardada para este indicador
        const notaGuardada = datosGuardados.find(item => 
            item.id_matricula === id_matricula &&
            item.idasginatura === idasignatura &&
            item[`nota${num_nota}`] !== ''  // Acceder dinámicamente a la nota correcta
        );
                
        console.log(notaGuardada);

        if (notaGuardada) {
            setModificado(true); // Marcar como modificado si hay una nota guardada
            //setNota(notaGuardada.nota)
        }
    }
};

  return (
      <>
          <input
              type="text"
              id={`nota-${id_matricula}-${num_nota}`}
              name={`nota-${id_matricula}`}
              value={Nota}
              className={`form-control form-control-sm ${modificado ? 'inputmodificado' : ''}`}
              onChange={(e) => onChangeNota(e)}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              maxLength={3}
              style={{textAlign:'center'}}
              autocomplete="off"
          />
      </>
  );
};