import React from 'react';

const MultipleBar = ({ bajo, basico, alto, superior }) => {
  // Calcula el total de porcentajes
  
  let total = 0;
  let vbajo = 0;
  let vbasico = 0;
  let valto = 0;
  let vsuperior = 0;

  if (bajo || basico || alto || superior){
     total = bajo + basico + alto + superior;
     vbajo = ((bajo / total) * 100).toFixed(1);
     vbasico = ((basico / total) * 100).toFixed(1);
     valto = ((alto / total) * 100).toFixed(1);
     vsuperior = ((superior / total) * 100).toFixed(1);
  }

  return (
    <div className="progress-stacked">
      <div className="progress" role="progressbar" aria-label="Bajo" aria-valuenow={bajo} aria-valuemin="0" aria-valuemax="100" style={{ width: `${vbajo}%` }}>
        <div className="progress-bar" style={{ backgroundColor: '#FF4560' }}>{`${vbajo}%`}</div>
      </div>
      <div className="progress" role="progressbar" aria-label="Básico" aria-valuenow={basico} aria-valuemin="0" aria-valuemax="100" style={{ width: `${vbasico}%` }}>
        <div className="progress-bar" style={{ backgroundColor: '#FFC300' }}>{`${vbasico}%`}</div>
      </div>
      <div className="progress" role="progressbar" aria-label="Alto" aria-valuenow={alto} aria-valuemin="0" aria-valuemax="100" style={{ width: `${valto}%` }}>
        <div className="progress-bar bg-info">{`${valto}%`}</div>
      </div>
      <div className="progress" role="progressbar" aria-label="Superior" aria-valuenow={superior} aria-valuemin="0" aria-valuemax="100" style={{ width: `${vsuperior}%` }}>
        <div className="progress-bar bg-primary">{`${vsuperior}%`}</div>
      </div>
    </div>
  );
};

export default MultipleBar;
