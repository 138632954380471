import React, { useContext, useEffect, useState } from 'react'
import { GetData } from '../services/GetData';
import { PostData } from '../services/PostData';
import { useParams, useNavigate } from 'react-router-dom';
import { getIDDocente } from '../services/Usuario';
import { SessionContext } from '../SessionContext';
import Swal from 'sweetalert2';
import NotaFilaIndiAlumno from '../components/NotaFilaIndiAlumno';

const CalificarNotasIndicador = () => {
  const { grado, grupo, idasignatura, idperiodo } = useParams();
  const [ListAlumnos, setListAlumnos] = useState([])  
  const [ListadoGrados, setListadoGrados] = useState([])  
  const [ListadoAsignaturas, setListadoAsignaturas] = useState([]) 
  const [Grado, setGrado] = useState(`${grado}-${grupo}`)
  const [NombreGrado, setNombreGrado] = useState("")
  const [Asignatura, setAsignatura] = useState(idasignatura)
  const [Periodo, setPeriodo] = useState(idperiodo)
  const [ListaPeriodo, setListaPeriodo] = useState([1,2,3,4]);
  const [listadoAlumnos, setListadoAlumnos] = useState([]);
  const {NotasSinGuardar, validarNotasStorage } = useContext(SessionContext);
  const [LoadingTabla, setLoadingTabla] = useState(false)    
  const [RefesTabla, setRefesTabla] = useState(false)    

  const navigate = useNavigate();

  useEffect(() => {
    if (Grado !== '0-0'){
        const pgrado = Grado.split('-');
        const tgrado = pgrado[0]
        const tgrupo = pgrado[1]
        GetData('asignaciones/listasignagradogrupo2/' + tgrado + '/' + tgrupo, null).then((result) => {
            setListadoAsignaturas(result.asignaturas)
        })  
    }
}, [Grado])

useEffect(() => {
    GetData('asignaciones/todosgrados/', null).then ((result) =>{
        let responseJSON = result;

        if (responseJSON.error === false){
            setListadoGrados(result.grados)
        }
    })

}, [])


  useEffect(() => {

        if (Grado === '0-0')
            return;    

        const pgrado = Grado.split('-');
        const tgrado = pgrado[0]
        const tgrupo = pgrado[1]

        GetData('grados/info/'+tgrado, null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setNombreGrado(result.nom_grado)
            }
        })
        
        const part_asig = Asignatura.split("-");
        const id_asig = part_asig[0];
        const asigppal = part_asig[1];       
       
        const data = {
            id_asignatura: id_asig,
            id_grado: grado,
            id_grupo: grupo,
            id_periodo: Periodo,
            id_asignautra_ppal: asigppal
        }

        setListAlumnos([])
        setLoadingTabla(true)
        PostData('calificaciones/notasalumnosadmin', data).then((result) => {
            if (result.error === false) {
                // Obtener datos guardados del localStorage
                const datosGuardadosJSON = localStorage.getItem('notasGuardadas');
                let datosGuardados = [];
    
                if (datosGuardadosJSON) {
                    datosGuardados = JSON.parse(datosGuardadosJSON);
                } else {
                    localStorage.setItem('notasGuardadas', '[{}]');
                }
    
                // Actualizar la lista de alumnos con las nuevas columnas
                const updatedListAlumnos = result.alumnos.map((alumno) => {
                    const updatedAlumno = { ...alumno };
                
                    // Definir los campos que deseas actualizar y sus correspondientes claves en datosGuardados
                    const campos = [
                        { campo: 'ina', key: 'ina' },
                        { campo: 'nota1', key: 'nota1' },
                        { campo: 'nota2', key: 'nota2' },
                        { campo: 'nota3', key: 'nota3' },
                        { campo: 'nota4', key: 'nota4' },
                    ];
                
                    campos.forEach(({ campo, key }) => {
                        const dato = datosGuardados.find(
                            (item) => item.id_matricula === alumno.id_matricula && item.idasginatura === idasignatura && item.campo === campo
                        );
                        if (dato) {
                            updatedAlumno[key] = parseFloat(dato.nota || dato.ina); // Asignar la nota o inasistencia dependiendo del campo
                        }
                    });
                
                    return updatedAlumno;
                });
                
                
                // Actualizar el estado con los nuevos datos de los alumnos
                setListAlumnos(updatedListAlumnos);
                validarNotasStorage(grado, grupo, idasignatura);
                setLoadingTabla(false);
            }
        });
  }, [Asignatura, Periodo])
     

    


  const obtenerClaseGrupo = (grupo) => {
    // Lógica para asignar una clase CSS basada en el grupo
    switch (grupo) {
      case 0:
        return 'borde-color-naranja';
      case 1:
        return 'borde-color-verde';
      case 2:
        return 'borde-color-fuxia';
      default:
        return '';
    }
  };

    // Función para manejar el cambio en las notas de un alumno
    const handleNotaChange = (indiceAlumno, indiceActividad, value) => {
        // Clonar el estado actual de las notas de los alumnos
        const nuevasNotas = [...listadoAlumnos];
        // Actualizar la nota del alumno en el índice correspondiente
        nuevasNotas[indiceAlumno][indiceActividad] = parseFloat(value);
        // Actualizar el estado de las notas de los alumnos
        setListadoAlumnos(nuevasNotas);
    };
    
    // Calcula el promedio de las notas de un alumno
    const calcularPromedioAlumno = (notas) => {
        if (notas){
            const notasValidas = notas.filter(nota => !isNaN(nota));
            const sumaNotas = notasValidas.reduce((acc, nota) => acc + nota, 0);
            return sumaNotas / notasValidas.length || 0;
        }else
            return 0;
    };

    

 
    // Función para eliminar las notas de un grado, grupo y asignatura específicos del almacenamiento local
    const eliminarNotas = (datosGuardados) => {
        // Filtrar las notas para mantener solo las que no coincidan con los IDs especificados
        const notasFiltradas = datosGuardados.filter((nota) => {
             return !(nota.id_grado === grado && nota.id_grupo === grupo && nota.idasginatura === idasignatura);
          });
           // Actualizar el almacenamiento local con las notas filtradas 
        localStorage.setItem('notasGuardadas', JSON.stringify(notasFiltradas));
    };

    const GuardarNotas = () => {

        const datosGuardadosJSON = localStorage.getItem('notasGuardadas');
        let datosGuardados = [];
        if (datosGuardadosJSON) {
            datosGuardados = JSON.parse(datosGuardadosJSON);
            const notasFiltradas = datosGuardados.filter((nota) => {                
                return nota.id_grado === grado && nota.id_grupo === grupo && nota.idasginatura === idasignatura;
            });
            if (notasFiltradas && notasFiltradas.length > 0) {
                const datos = {
                    id_grado: grado,
                    id_grupo: grupo,
                    id_asginatura: idasignatura,
                    id_periodo: Periodo,
                    notas: notasFiltradas
                }

                 // Mostrar mensaje de carga
                Swal.fire({
                    title: 'Guardando Notas',
                    text: 'Por favor, espera...',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    }
                });

                PostData('calificaciones/guardarnotasmasivofinal/', datos).then ((result) =>{
                    if (result.error === false){
                        eliminarNotas(datosGuardados);
                        validarNotasStorage(grado, grupo, idasignatura)
                        setRefesTabla(!RefesTabla)
                        Swal.fire({
                            title: 'Guardar Notas',
                            text: result.mensaje,
                            icon: 'success',
                            confirmButtonText: 'Aceptar'
                          });
                    }
                });
            }
        }

        
    }

    const irAGrado = (id_asginatura, id_periodo) => {
        const pgrado = Grado.split('-');
        const tgrado = pgrado[0]
        const tgrupo = pgrado[1]
         navigate(`/home/modcalificaciones/${tgrado}/${tgrupo}/${id_asginatura}/${id_periodo}`);
        window.location.reload();
    }

    
    const abrirAyuda = () => {
        
        const modal = document.getElementById('modalAyuda');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
        }
    }

    const CancelarGuardado = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto! ¿Borrar notas temporales guardadas?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, borralo'
        }).then((result) => {
            if (result.isConfirmed) {
                const datosGuardadosJSON = localStorage.getItem('notasGuardadas');
                let datosGuardados = [];
    
                if (datosGuardadosJSON) {
                    datosGuardados = JSON.parse(datosGuardadosJSON);
                }
    
                // Filtrar los datos guardados para la asignatura actual
                const datosFiltrados = datosGuardados.filter(
                    (item) => item.idasginatura === idasignatura && item.id_grado === grado && item.id_grupo === grupo
                );
    
                // Eliminar los datos filtrados del localStorage
                datosFiltrados.forEach((item) => {
                    const index = datosGuardados.indexOf(item);
                    if (index !== -1) {
                        datosGuardados.splice(index, 1);
                    }
                });
    
                // Guardar los datos actualizados en el localStorage
                localStorage.setItem('notasGuardadas', JSON.stringify(datosGuardados));
    
                // Mostrar mensaje de confirmación
                Swal.fire(
                    'Borrado!',
                    'Notas temporales guardadas borradas correctamente.',
                    'success'
                );
                validarNotasStorage(grado, grupo, idasignatura);
                setRefesTabla(!RefesTabla)
                // Aquí podrías realizar cualquier otra acción necesaria después de borrar
            }
        });
            
        
            

    }

    const BorrarNotasTemp = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto! ¿Estas seguro de borrar las notas temporales?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, procedamos'
        }).then((result) => {
            if (result.isConfirmed) {

                localStorage.setItem('notasGuardadas', '[{}]');
                validarNotasStorage(grado, grupo, idasignatura)
            }
        });    
    }

    const regresar = () => {
        navigate('/home/indicadores/'+grado+'/'+grupo+'/'+idasignatura)
    }

  return (
    <>
         <section className='section dashboard'>
            <div className="card cnotas">
                <div className='card-header'>Ingreso de Calificaciones </div>    

                <div className="card-body pb-0 calificciones">

                <div className='row'>
                            <div className='col-md-2'>
                                <strong>Grado: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grado} onChange={(e) => setGrado(e.target.value)} >
                                <option defaultValue={null}>Seleccionar</option>
                                {ListadoGrados.map(tipo => {
                                            return <option key={`${tipo.id_grado}-${tipo.id_grupo}`} value={`${tipo.id_grado}-${tipo.id_grupo}`}>{tipo.nombre_grado} - 0{tipo.id_grupo}</option>
                                    })}
                                </select> 
                            </div>                                                         
                            <div className='col-md-2'>
                                <strong>Asignatura: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Asignatura} onChange={(e) => irAGrado(e.target.value, Periodo)} >
                                <option defaultValue={null}>Seleccionar</option>
                                {ListadoAsignaturas.map(asig => {
                                            return <option key={`${asig.id_asignatura}`} value={`${asig.id_asignatura}-${asig.id_asignautra_ppal}`}>{asig.nombre_asig}</option>
                                    })}
                                </select> 
                            </div>                                                         
                            <div className='col-md-1'>
                                <strong>Período: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Periodo} onChange={(e) => irAGrado(Asignatura, e.target.value)} >
                                {ListaPeriodo.map(periodo => {
                                            return <option key={`${periodo}`} value={`${periodo}`}>{periodo}</option>
                                    })}
                                </select> 
                            </div>                                                         
                            <div className='col-md-2'>                            
                                <button type="button" className="btn btn-outline-success btn-sm mt-4 ml-2" onClick={GuardarNotas} disabled={!NotasSinGuardar || !grado || !grupo} >
                                    <i className="ri-save-2-fill"></i> Guardar Notas
                                </button>
                            </div>  
                            <div className='col-md-4' style={{marginTop: '25px'}}>
                                <span className='ml-4 label'>Grado:</span> <span className='texto'>{NombreGrado} - 0{grupo}</span>  
                                <span className='ml-4 label'>N° Alumnos: </span> <span className='texto'>{ListAlumnos.length}</span>                                
                            </div>
                           
                            <div className='col-md-1' style={{marginTop: '20px'}}>
                                <a href='#' data='Borrar Notas temporales' onClick={BorrarNotasTemp}><img src='/assets/img/borrar.png' width={32} /> </a>
                                <a href='#' data='Instrucciones' onClick={abrirAyuda}><img src='/assets/img/pregunta.png' width={32} /> </a>
                            </div>

                    </div>   
                    <div className='row'>
                             <div className='col-md-12' style={{marginTop: '8px'}}>
                                {NotasSinGuardar && 
                                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                            <i className="bi bi-info-circle me-1"></i>
                                            Hay notas sin guardar, no olvide Guardar  <span class="badge bg-danger" onClick={()=>CancelarGuardado()} style={{cursor: 'pointer'}}><i class="bi bi-exclamation-octagon me-1"></i> Cancelar</span>
                                        </div>
                                }
                            </div>
                    </div>
                    <form className='form mt-2'>
                    {LoadingTabla && 
                    <div className="progress mt-1 mb-2">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    } 
                    <table className="table table-striped table-hover">
                            <thead>
                            <tr key={0}>
                                <th scope="col" style={{width: '5%'}}>#</th>
                                <th scope="col" style={{width: '5%'}}>N°</th>
                                <th scope="col" >Nombre del Alumnos</th>
                                <th scope="col" >Ina</th>
                                    <th>
                                        N1
                                    </th> 
                                    <th>
                                        N2
                                    </th> 
                                    <th>
                                        N3
                                    </th> 
                                    <th>
                                        N4
                                    </th> 
                                    <th>
                                        Prom
                                    </th> 
                                
                            </tr>
                            </thead>
                            <tbody>
                                {ListAlumnos.map((alum, index) => {
                                    return (
                                        <NotaFilaIndiAlumno index={index} calcularPromedioAlumno={calcularPromedioAlumno}  alum={alum} grado={grado} grupo={grupo} idasignatura={idasignatura} />
                                    )
                                })}
                                
                            </tbody>  
                    </table>
                    </form>            
                </div>

            </div>
        </section>    


        <div className="modal fade" id="modalAyuda" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-md">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> Instrucciones de la Planilla</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    <ol>
                        <li>
                            <strong>Ingreso de Notas:</strong> 
                            Después de introducir las notas correspondientes, es necesario hacer clic en el botón "Guardar Notas" para registrar los cambios.
                        </li>
                        <li>
                            <strong>Modificación de Notas:</strong> 
                            Si se modifica una nota después de haber ingresado otras, se muestra un mensaje indicando que aún quedan notas por guardar. Esto asegura la integridad de los registros y evita pérdida de datos.
                        </li>
                        <li>
                            <strong>Eliminación de Notas:</strong> 
                            Para eliminar una nota, simplemente se borra del campo correspondiente y se hace clic en el botón "Guardar Notas". Esto actualiza los registros de manera adecuada.
                        </li>
                        <li>
                            <strong>Cambio de Grado:</strong> 
                             El campo "Grado" permite cambiar entre grados de forma sencilla, facilitando la gestión de notas para diferentes grupos académicos.
                        </li>
                    </ol>                 

                    </div>
                    <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
                    </div>
                  </div>
                </div>
                </div>                          
    </>
  )
}

export default CalificarNotasIndicador