import React, { useContext, useState } from 'react'
import { SessionContext } from '../SessionContext';

const InputIna = ({valor, id_matricula, grado, grupo, id_asignatura }) => {
    const [Inasistencia, setInasistencia] = useState(valor)
    const { validarNotasStorage } = useContext(SessionContext);
    
  
    const onChangeNota = (e) => {
        const inputValue = e.target.value;
        const isValidNumber = /^[0-5]$/.test(inputValue) || inputValue === '';
    
        // Verificar si el caracter presionado es un número válido entre 0 y 5,
        // un punto, la tecla de retroceso, la flecha izquierda o la flecha derecha
        if (
            isValidNumber ||                   // Número válido entre 0 y 5
            e.key === 'Backspace' ||          // Tecla de retroceso
            e.key === 'ArrowLeft' ||          // Flecha izquierda
            e.key === 'ArrowRight'            // Flecha derecha
        ) {
            // Permitir la actualización del estado si la entrada es válida
            setInasistencia(inputValue);
            guardaLocalStorare(inputValue);
        } else {
            // Evitar que el caracter ingresado se refleje en el campo de entrada
            e.preventDefault();
        }
    }

    const guardaLocalStorare = (ina) => {      
          
      // 1. Obtener los datos actuales del localStorage
      const datosGuardadosJSON = localStorage.getItem('notasGuardadas');
      let datosGuardados = [];
      if (datosGuardadosJSON) {
          datosGuardados = JSON.parse(datosGuardadosJSON);
      }
      // 2. Buscar si ya existe un item con la id_matricula e idasginatura
      const index = datosGuardados.findIndex(
        (item) =>  item.id_matricula === id_matricula && item.idasginatura === id_asignatura && item.campo === 'ina'
      );

      if (index !== -1) {
        // 3. Si existe, actualizar los valores de las notas
        datosGuardados[index] = {
          ...datosGuardados[index],
          ina: ina,
        };
      } else {
        // 4. Si no existe, crear un nuevo objeto con los datos y agregarlo al arreglo
        const nuevoItem = {
          id_matricula: id_matricula,
          id_grado: grado,
          id_grupo: grupo,
          idasginatura: id_asignatura,
          campo: 'ina',
          ina: ina,
        };
        
        datosGuardados.push(nuevoItem);
      }
      
      // 5. Guardar el arreglo actualizado en el localStorage
      localStorage.setItem('notasGuardadas', JSON.stringify(datosGuardados));

      validarNotasStorage(grado, grupo, id_asignatura);
    }

    const handleKeyDown = (e) => {
            
        if (e.key === 'Enter') {
          e.preventDefault(); // Evita que se envíe el formulario por defecto
        
  
          const form = e.target.form;
          const index = Array.prototype.indexOf.call(form, e.target);
          if (index < form.elements.length - 1)
             form.elements[index + 1].focus();
        }
      }

      const handleFocus = (e) => {
          // Seleccionar todo el contenido del campo de entrada
        e.target.select();
      };
  return (
    <>
         <input  
            type='text'
            id={`ina-${id_matricula}-${id_asignatura}`}
            name={`ina-${id_matricula}-${id_asignatura}`} 
            value={valor}
            className='form-control form-control-sm ' 
            onChange={(e) => onChangeNota(e)}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            maxLength={2}
            style={{textAlign:'center'}}
            />
    </>
  )
}
export default InputIna