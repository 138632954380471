import React, { createContext, useState, useEffect } from 'react';

// Crea el contexto de sesión
export const SessionContext = createContext();

// Crea el proveedor de sesión
export const SessionProvider = ({ children }) => {
  // Obtén los datos de usuario almacenados en el almacenamiento local al cargar la aplicación
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')) || null);
  const [selectedCampus, setSelectedCampus] = useState(localStorage.getItem('selectedCampus') || 'default'); // Sede predeterminada por defecto
  const defaultAvatar = 'usuario.png';
  const [FotoPerfil, setFotoPerfil] = useState(user && user.avatar ? user.avatar : defaultAvatar);
  const [NotasSinGuardar, setNotasSinGuardar] = useState(false);

  //const [FotoPerfil, setFotoPerfil] = useState(user?.avatar || '/assets/img/usuario.png'); // Sede predeterminada por defecto

  // Función para iniciar sesión
  const login = (userData) => {
    setUser(userData);
  };

  // Función para cerrar sesión
  const logout = () => {   
    setUser(null);
    setSelectedCampus('default');
  };

    //valida si existen notas sin guardar en la planilla actual
    const validarNotasStorage = (grado, grupo, id_asginatura) => {
      const datosGuardadosJSON = localStorage.getItem('notasGuardadas');
      let datosGuardados = [];
      if (datosGuardadosJSON) {
        datosGuardados = JSON.parse(datosGuardadosJSON);
      }
      // Buscar si ya existe un item con la id_matricula, idasginatura e id_actividad
      const index = datosGuardados.findIndex(
        (item) => item.id_grado === grado && item.id_grupo === grupo && item.idasginatura === id_asginatura
      );
  
      if (index !== -1)
          setNotasSinGuardar(true)
      else
          setNotasSinGuardar(false)  
  }
  

  // Guarda los datos de usuario en el almacenamiento local al actualizar el estado de usuario
  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem('selectedCampus', selectedCampus);
  }, [selectedCampus]);

  useEffect(() => {
    // Verifica si user no es null antes de modificar su propiedad avatar
    if (user) {
        user.avatar = FotoPerfil || defaultAvatar;
        localStorage.setItem('userData', JSON.stringify(user));
    }
  }, [FotoPerfil]);


  // Define los valores del contexto

  // Retorna el proveedor del contexto con los hijos proporcionados
  return (
    <SessionContext.Provider value={{ user, setUser, login, logout, selectedCampus, setSelectedCampus, FotoPerfil, setFotoPerfil, validarNotasStorage, NotasSinGuardar  }}>
      {children}
    </SessionContext.Provider>
  );
};
