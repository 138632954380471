import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import { SessionContext } from './SessionContext';
import SeleccionarSede from './pages/SeleccionarSede';


function Rutas() {
  const [sedeSeleccionada, setSedeSeleccionada] = useState(JSON.parse(localStorage.getItem('SeleccionarSede')) || false);
  const { user } = useContext(SessionContext);
  
  return (
    <Router>
        <Routes>
          <Route path="/" element={  !user ? (<LoginPage />):( <Navigate to="/home"/>)} />
         {/*  <Route path="/sedes" element={user ? <SeleccionarSede setSedeSeleccionada={setSedeSeleccionada} /> : <Navigate to="/" />} /> */}
          <Route path="/home/*" element={user ? <HomePage /> : <Navigate to="/" />} />
        </Routes>
    </Router>
  );
}

export default Rutas;
