export function getToken() {    
    const usuario = JSON.parse(localStorage.getItem('userData'));
    return usuario ? usuario.token : '';
  }
  
  export function getID() {
    const usuario = JSON.parse(localStorage.getItem('userData'));
    return usuario ? usuario.id_usuarios : '';
  }

  export function getTipoUser() {
    const usuario = JSON.parse(localStorage.getItem('userData'));
    return usuario ? usuario.id_tipos_usuario : '';
  }

  export function getSede() {
    const sede = localStorage.getItem('selectedCampus');
    return sede ? sede : '';
  }

  export function Usuario() {
    const usuario = JSON.parse(localStorage.getItem('userData'));
    return usuario ? usuario : '';
  }