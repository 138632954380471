import React from 'react';

const MensajeDesempenio = ({ bajo, basico, alto, superior }) => {
  // Obtener el porcentaje más alto
  const porcentajeMaximo = Math.max(bajo, basico, alto, superior);

  // Mensaje predeterminado
  let mensaje = "La institución se encuentra en una buena posición.";
  let tipoAlerta = "success"; // Color predeterminado del alerta

  // Determinar el mensaje y el tipo de alerta basado en el porcentaje más alto
  if (porcentajeMaximo === bajo) {
    mensaje = "La institución tiene un porcentaje alto de desempeño <strong>Bajo</strong>. Se deben tomar medidas para mejorar este aspecto.";
    tipoAlerta = "danger"; // Alerta de color rojo para desempeño bajo
  } else if (porcentajeMaximo === basico) {
    mensaje = "La institución tiene un porcentaje alto de desempeño <strong>Básico</strong>. Se pueden implementar estrategias para alcanzar un nivel superior.";
    tipoAlerta = "warning"; // Alerta de color amarillo para desempeño básico
  } else if (porcentajeMaximo === alto) {
    mensaje = "La institución tiene un porcentaje alto de desempeño <strong>Alto</strong>. Este es un buen indicador, pero siempre se puede buscar la excelencia.";
    tipoAlerta = "info"; // Alerta de color azul para desempeño alto
  } else if (porcentajeMaximo === superior) {
    mensaje = "La institución tiene un porcentaje alto de desempeño <strong>Superior</strong>. Felicitaciones por mantener un alto nivel de rendimiento.";
    tipoAlerta = "success"; // Alerta de color verde para desempeño superior
  }

  return (    
    <div className={`alert alert-${tipoAlerta} alert-dismissible fade show`} role="alert">
      <div dangerouslySetInnerHTML={{ __html: mensaje }} />
    </div>
  );
};

export default MensajeDesempenio;
