import React, { useRef, useState, useCallback, useContext } from 'react';
import Webcam from 'react-webcam';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage'; // Helper function to crop the image
import imageCompression from 'browser-image-compression';
import { URL_SERVICIOS } from '../services/UrlWebServis';
import { getID, getToken } from '../services/Usuario';
import config from '../config';
import { SessionContext } from '../SessionContext';

const CaptureAndResize = () => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const { setFotoPerfil } = useContext(SessionContext);

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(capturedImage, croppedArea);
      setCroppedImage(croppedImage);
    } catch (error) {
      console.error(error);
    }
  }, [capturedImage, croppedArea]);

  const resizeImage = async (image) => {
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(image, options);
      return compressedFile;
    } catch (error) {
      console.error("Error while resizing the image", error);
      return null;  // Asegúrate de devolver null en caso de error
    }
  };

  const handleImageUpload = async () => {
    
    if (croppedImage) {
      const response = await fetch(croppedImage);
      const blob = await response.blob();
      const resizedImage = await resizeImage(blob);
      
      const formData = new FormData();
      formData.append('image', resizedImage);

      try {
        const uploadResponse = await fetch(URL_SERVICIOS + '/uploadimage/upload/' + getID() + '/' + getToken(), {
          method: 'POST',
          body: formData
        });

        if (uploadResponse.ok) {
          const responseData = await uploadResponse.json();
            console.log('Image uploaded successfully:', responseData);
            if (responseData.status === 'success'){
                setFotoPerfil(responseData.filename)
                setCapturedImage(null)
                setCroppedArea(null)
                setCroppedImage(null)
            }
        } else {
          console.error('Image upload failed:', uploadResponse.statusText);
        }
      } catch (error) {
        console.error('Error during image upload:', error);
      }
    }
  };

  return (
    <div className="containerCam">
      {!capturedImage ? (
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={350}
            height={350}
          />
          <button onClick={captureImage}>Capturar Imagen</button>
        </div>
      ) : (
        <div>
        <div className="crop-container">
          <Cropper
            image={capturedImage}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            showGrid={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="controls mt-3">
          <input
            type="range"
            className="form-range"
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e) => setZoom(e.target.value)}
          />
        </div>
        <button className="btn btn-secondary mt-3" onClick={showCroppedImage}>
          Recortar Imagen
        </button>
      </div>
      )}

      {croppedImage && (
        <div>
          <h2>Imagen Redimensionada</h2>
          <img src={croppedImage} alt="Cropped" />
          <button onClick={handleImageUpload}>Guardar Imagen</button>
        </div>
      )}
    </div>
  );
};

export default CaptureAndResize;
