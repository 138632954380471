import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const BotonTabla = ({icono, titulo, evento}) => {
  return (
    <>
     <OverlayTrigger
            key="top"
            placement="top"
            overlay={
            <Tooltip id={`tooltip-top`}>
                {titulo}
            </Tooltip>
            }
        >
     <img   src={`/assets/img/${icono}`} className='icono_tabla' width={25} onClick={()=>evento()} /> 
     </OverlayTrigger>
    </>
  )
}

export default BotonTabla