import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import { GetData } from '../../services/GetData';
import MensajeDesempenio from './MensajeDesempenio';
import MultipleBar from '../../components/MultipleBar';

const PorcDesempGrados = () => {

  const [ListadoGrados, setListadoGrados] = useState([])  
  const [ListadoPorc, setListadoPorc] = useState([])  
  const [Periodo, setPeriodo] = useState(0)  
  const [PeriodoActual, setPeriodoActual] = useState(0)  
  const [Loading, setLoading] = useState(false)


  useEffect(() => {
        GetData('periodos/actual/', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setPeriodo(result.periodo)
                setPeriodoActual(result.periodo)
            }
        })

        
        
  }, [])

  useEffect(() => {
        setLoading(true)
        GetData('estadisticas/porcdesempperiodo/' + Periodo, null).then ((result) =>{
          let responseJSON = result;          
          if (responseJSON.error === false){

              setListadoGrados(result.grados)
              setListadoPorc(result.porc_todos)
          }
          setLoading(false)
      })
  }, [Periodo])
  

 

    // Transformar datos para el gráfico
    let seriesData = [];
    let categories = [];

    let bajos = [];
    let basicos = [];
    let altos = [];
    let superiores = [];

    ListadoGrados.forEach((gradoGrupo) => {
        
        const { grado, grupo, desempenios } = gradoGrupo;
        categories.push(`${grado} - ${grupo}`);

        bajos.push(desempenios.bajo);
        basicos.push(desempenios.basico);
        altos.push(desempenios.alto);
        superiores.push(desempenios.superior);
        

        // Agregar los datos de cada nivel de desempeño a la serie
       
    });

    // El resto de tu código para el gráfico...


    seriesData.push({
        name: "Bajo",
        data: bajos
    });

    seriesData.push({
        name: "Básico",
        data: basicos
    });

    seriesData.push({
        name: "Alto",
        data: altos
    });

    seriesData.push({
        name: "Superior",
        data: superiores
    });

// Configuración del gráfico
const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%'
    },
    colors: ['#FF4560', '#FFC300', '#36B019', '#008FFB'],
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    title: {
      text: 'Desempeño por Grado y Período'
    },
    xaxis: {
      categories: categories
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    }
  };

  const porcgt = ListadoPorc;

  const optionsDonus = {
    chart: {
      height: 350,
      type: 'donut',
      toolbar: {
        show: true
      }
    },
    labels: ['Bajo', 'Básico', 'Superior', 'Alto'],
    colors: ['#FF4560', '#FFC300', '#36B019', '#008FFB'],
    series: [porcgt.bajo, porcgt.basico, porcgt.superior, porcgt.alto],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  
  return (
    <>
          <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Porcenta de Desempeño por Grados Período: {Periodo}</div>                    
                    <div className="card-body pb-0">

                        <div className='row'>
                            <div className='col-md-2'>
                                    <strong>Período: </strong>
                                    <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Periodo} onChange={(e) => setPeriodo(e.target.value)}>
                                    {Array.from({length: PeriodoActual}, (_, index) => index + 1).map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>      
                        </div>

                        <div className='alert alert-info alert-dismissible fade show mt-2'>
                        <i class="bi bi-info-circle me-1"></i> El gráfico de barras muestra el porcentaje de desempeño académico por grado y período. Cada barra representa un grado y grupo específico, con los colores indicando el porcentaje de estudiantes en cada categoría de desempeño: rojo para 'Bajo', amarillo para 'Básico', verde para 'Alto' y azul para 'Superior'. Este análisis proporciona una visión general del rendimiento académico de los estudiantes a lo largo de los períodos escolares.
                        </div>
                        {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                        <div className='row ml-3'>
                           <MensajeDesempenio bajo={ListadoPorc.bajo} basico={ListadoPorc.basico} alto={ListadoPorc.alto} superior={ListadoPorc.superior} />
                        </div>
                        <div className='row'>
                            <div className='col-md-6'> <MultipleBar bajo={porcgt.bajo} basico={porcgt.basico} alto={porcgt.alto} superior={porcgt.superior} /></div>
                        </div>

                        <div className='row'>
                            <Chart options={options} series={seriesData} type="bar" height={1200} />
                        </div>
                       
                       
                        
                    </div>
                </div>
            </section>
    </>
  )
}

export default PorcDesempGrados