import React, { useEffect, useState } from 'react'
import { validarEmail, calculateEdad } from '../components/utils';
import { useParams } from 'react-router-dom';


const HojaMatricula = () => {
     const { idmatricula } = useParams();
     /* datos select */   
     const [ListaGados, setListaGados] = useState([])
     const [ListaGrupos, setListaGrupos] = useState([])
     const [ListaEstados, setListaEstados] = useState([])
     const [TiposIdentificacion, setTiposIdentificacion] = useState([])
     const [ListaMunicipios, setListaMunicipios] = useState([])
     const [ListaTSanguineo, setListaTSanguineo] = useState([])
     const [ListaJornadas, setListaJornadas] = useState([])

    /* datos dela matricula */
    const [IdMatricula, setIdMatricula] = useState(0)
    const [NumMatricula, setNumMatricula] = useState(0)
    const [IdAlumno, setIdAlumno] = useState(0)
    const [NombreAlumno, setNombreAlumno] = useState('')
    const [Identificacion, setIdentificacion] = useState('')
    const [GradoM, setGradoM] = useState(null)
    const [GrupoM, setGrupoM] = useState(null)    
    const [JornadaM, setJornadaM] = useState(null)    
    const [EstadoM, setEstadoM] = useState(null)    
    const [FechaNacM, setFechaNacM] = useState(null)    
    const [Edad, setEdad] = useState(0)    
    const [TelefonoM, setTelefonoM] = useState(null)    
    const [DireccionM, setDireccionM] = useState(null)    
    const [TipoIdent, setTipoIdent] = useState(null)    
    const [Municipio, setMunicipio] = useState(null)    
    const [TipoSangre, setTipoSangre] = useState(null)    
    const [SexoM, setSexoM] = useState(null)    
    const [EmailM, setEmailM] = useState(null)    
    const [emailValido, setEmailValido] = useState(true);  

    useEffect(() => {
      
        ///idmatricula
    }, [])
    

    /* campos opcionales */
    const [formData, setFormData] = useState({
        vivesolo: 'N',
        emabarazo: 'N',
        trabajo: 'N',
        mat_pat_tempra_edad: 'N',
        victima_discrimi: 'N',
        victima_agresion: 'N',
        educacion_preescolar: 'N',
        ret_est_sin_terminar: 'N',
        abandonos_temp: 'N',
        repetida_anio: 'N',
        rept_grado_actual: 'N',
        dificultades_aprendisaje: 'N',
        vinculado_mod_educ_inicial: 'N',
        discriminacion: [],
        tipo_vivienda: null,
        vivienda_propiedad: null,
        antece_disc_vida: null,
        asistencia_anio_anterior: null,
        otra_discrimina: '',
        que_desa_estudiar: '',
        num_pers_hogar: '',
        num_anios_preescolar: '',
        num_meses_por_fuera: '',
        num_anios_repet: ''
        // Agregar más campos según sea necesario
      });

      const handleChange = (e) => {
        const { name, value, type, checked, options  } = e.target;
    
        if (type === 'checkbox') {
          setFormData({
            ...formData,
            [name]: checked ? 'S' : 'N',
          });
        } else if (options && options instanceof HTMLCollection) {
            // Si es un select múltiple
            const selectedOptions = Array.from(options).filter(option => option.selected).map(option => option.value);
        
            setFormData({
              ...formData,
              [name]: selectedOptions,
            });
        } else {
          setFormData({
            ...formData,
            [name]: value,
          });
        }
        console.log(formData)
      };

    const [Loading, setLoading] = useState(false)
    

  return (
    <>
    <div className="pagetitle">
      
            <nav>
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                <li className="breadcrumb-item">Hola Matricula</li>
                
                </ol>
            </nav>
            </div>

            
            <section className='section dashboard container'>
            <div className="card">
                
                <div className="card-body">
                    <h4 className="card-title">Hoja de Matricula</h4>
                    <hr className='bordetitulo' />
                {Loading && 
                        <div className="progress mt-3">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }     
                        <div className='row'>

                            <div className='col-md-2'>
                                        <span className='label'>Grado: </span>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={GradoM} onChange={(e) => setGradoM(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGados.map(tipo => {
                                                    return <option key={tipo.id_grados} value={tipo.id_grados}>{tipo.nombre_grado}</option>
                                            })}
                                        </select> 
                                    
                            </div>    
                            <div className='col-md-2'>
                                        <span className='label'>Grupo: </span>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={GrupoM} onChange={(e) => setGrupoM(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGrupos.map(tipo => {
                                                    return <option key={tipo.id_grupo} value={tipo.id_grupo}>{tipo.num_grupo}</option>
                                            })}
                                        </select> 
                                    
                            </div>  
                            <div className='col-md-2'>
                                        <span className='label'>Jornada: </span>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={JornadaM} onChange={(e) => setJornadaM(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaJornadas.map(tipo => {
                                                    return <option key={tipo.id_jornadas} value={tipo.id_jornadas}>{tipo.jornada}</option>
                                            })}
                                        </select> 
                            </div>
                            <div className='col-md-2'>
                                        <span className='label'>Estado: </span>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={EstadoM} onChange={(e) => setEstadoM(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaEstados.map(tipo => {
                                                    return <option key={tipo.id_estado_matricula} value={tipo.id_estado_matricula}>{tipo.nombre_estado}</option>
                                            })}
                                        </select> 
                            </div>

                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <div className="input-group">
                                <span className='label'>Nombre Alumno: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" style={{width: '90%'}} id="nombres" name="nombres" value={NombreAlumno} onChange={(e) => setNombreAlumno(e.target.value)} />
                                <button type="button" className="btn btn-info btn-sm" title='Seleccionar Alumno' onClick="" >
                                        <i className="bi bi-search"></i>
                                </button>
                                </div>
                            </div>    
                            <div className='col-md-2'>
                                <span className='label'>Tipo Identificación: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={TipoIdent} onChange={(e) => setTipoIdent(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {TiposIdentificacion.map(tipo => {
                                                    return <option key={tipo.id_tipo_documento} value={tipo.id_tipo_documento}>{tipo.abreviatura_doc}</option>
                                            })}
                                        </select> 
                            </div>  
                            <div className='col-md-4'>
                                <span className='label'>No. Identificación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>     
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-5'>
                                <span className='label'>Lugar de Expedición: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Municipio} onChange={(e) => setMunicipio(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaMunicipios.map(tipo => {
                                                    return <option key={tipo.id_municipio} value={tipo.id_municipio}>{tipo.lugar}</option>
                                            })}
                                        </select> 
                            </div> 
                            <div className='col-md-3'>
                                <span className='label'>Fecha de Nacimiento: </span>
                                <input type="date" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={FechaNacM} onChange={(e) => setFechaNacM(e.target.value)} />
                            </div>     
                            <div className='col-md-1'>
                                <span className='label'>Edad: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="edad" name="edad" value={Edad} disabled  />
                            </div>
                            <div className='col-md-3'>
                                <span className='label'>Tipo de Sangre: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={TipoSangre} onChange={(e) => setTipoSangre(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaTSanguineo.map(tipo => {
                                                    return <option key={tipo.tipo} value={tipo.nombre_tiposangre}>{tipo.tipo}</option>
                                            })}
                                        </select> 
                            </div> 
                          
                        </div>
                        <div className='row mt-2'>
                        <div className='col-md-3'>
                                <span className='label'>Sexo: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={SexoM} onChange={(e) => setSexoM(e.target.value)} >
                                        <option value='M'>Masculino</option>
                                        <option value='F'>Femenino</option>
                                </select>
                            </div>
                             <div className='col-md-3'>
                                <span className='label'>Teléfono / Célular: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={TelefonoM} onChange={(e) => setTelefonoM(e.target.value)} />
                            </div>   
                            
                            <div className='col-md-5'>
                                <span className='label'>Dirección: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={DireccionM} onChange={(e) => setDireccionM(e.target.value)} />
                            </div>     

                            <div className='col-md-6'>
                                <span className='label'>Correo electrónico: </span>
                                <input type="email" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold', border: emailValido ? '1px solid #ced4da' : '1px solid #dc3545' }} className="form-control form-control-sm" id="email" name="email" value={EmailM} onChange={(e) => setEmailM(e.target.value)} />
                            </div>  
                            <div className='col-md-3'>
                                <span className='label'>Entidad de Salud: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="email" name="email" value={EmailM} onChange={(e) => setEmailM(e.target.value)} />
                            </div>  
                            <div className='col-md-3'>
                                <span className='label'>Sisbén: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="email" name="email" value={EmailM} onChange={(e) => setEmailM(e.target.value)} />
                            </div>  
                            <div className='col-md-6'>
                                <span className='label'>Institución donde curso año anterior: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="email" name="email" value={EmailM} onChange={(e) => setEmailM(e.target.value)} />
                            </div>  
                        </div>

                        <h4 className="card-title">Datos de Padres y Acudiente</h4>
                        <hr className='bordetitulo' />

                        <div className='row mt-2'>
                            <div className='col-md-3'>
                                <span className='label'>No. Identificación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-6'>
                                <span className='label'>Nombres y Apellidos del Padre: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-3'>
                                <span className='label'>Célular: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-2'>
                                <span className='label'>Ocupación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-4'>
                                <span className='label'>Corre Electrónico: </span>
                                <input type="email"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>       
                            <div className='col-md-2'>
                            <div className="form-check mt-4">
                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked="" />
                                <label className="form-check-label" for="gridRadios1">
                                    Es Acudiente
                                </label>
                                </div>
                            </div>               
                        </div>
                        <hr className='bordetitulo mt-2' />
                        <div className='row mt-2'>
                            <div className='col-md-3'>
                                <span className='label'>No. Identificación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-6'>
                                <span className='label'>Nombres y Apellidos de la Madre: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-3'>
                                <span className='label'>Célular: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-2'>
                                <span className='label'>Ocupación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-4'>
                                <span className='label'>Corre Electrónico: </span>
                                <input type="email"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>       
                            <div className='col-md-2'>
                            <div className="form-check mt-4">
                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked="" />
                                <label className="form-check-label" for="gridRadios1">
                                    Es Acudiente
                                </label>
                                </div>
                            </div>        
                        </div>
                        <hr className='bordetitulo mt-2' />
                        <div className='row mt-2'>
                            <div className='col-md-3'>
                                <span className='label'>No. Identificación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-6'>
                                <span className='label'>Nombres y Apellidos del Acudiente: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-3'>
                                <span className='label'>Célular: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-2'>
                                <span className='label'>Ocupación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>               
                            <div className='col-md-4'>
                                <span className='label'>Corre Electrónico: </span>
                                <input type="email"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>       
                            <div className='col-md-2'>
                            <div className="form-check mt-4">
                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked="" />
                                <label className="form-check-label" for="gridRadios1">
                                    Es Acudiente
                                </label>
                                </div>
                            </div>  
                        </div>

                        <h4 className="card-title">Datos Adicionales</h4>
                        <hr className='bordetitulo' />

                        <div className='row mt-2'>
                                <div className='col-md-6'>

                                <div className="form-check">
                                    <input name='vivesolo' value={formData.vivesolo} id='vivesolo'  className="form-check-input" type="checkbox" onChange={handleChange}  />
                                    <label className="form-check-label" for="vivesolo">Vive solo</label>
                                </div>
                                <div className="form-check">
                                    <input name='emabarazo'  value={formData.emabarazo} className="form-check-input" type="checkbox" id="emabarazo" onChange={handleChange}  />
                                    <label className="form-check-label" for="emabarazo">Ebarazo</label>
                                </div>
                                <div className="form-check">
                                    <input name='trabajo' value={formData.trabajo} className="form-check-input" type="checkbox" id="trabajo" onChange={handleChange}  />
                                    <label className="form-check-label" for="trabajo">Trabaja</label>
                                </div>
                                <div className="form-check">
                                    <input name='mat_pat_tempra_edad'  value={formData.mat_pat_tempra_edad} className="form-check-input" type="checkbox" id="mat_pat_tempra_edad" onChange={handleChange}  />
                                    <label className="form-check-label" for="mat_pat_tempra_edad">Mat. Pat. Temprana edad</label>
                                </div>
                                <div className="form-check">
                                    <input name='victima_discrimi'  value={formData.victima_discrimi} className="form-check-input" type="checkbox" id="victima_discrimi" onChange={handleChange}  />
                                    <label className="form-check-label" for="victima_discrimi">Víctima de Discriminación</label>
                                </div>
                                <div className="form-check">
                                    <input name='victima_agresion'  value={formData.victima_agresion} className="form-check-input" type="checkbox" id="victima_agresion" onChange={handleChange}  />
                                    <label className="form-check-label" for="victima_agresion">Víctima de Agresión</label>
                                </div>
                                <div className='label'>Discriminación</div>                
                                <select name="discriminacion" value={formData.discriminacion} multiple style={{width:'280px', height:'150px'}}  onChange={handleChange}>
                                        <option value="01">01 Discapacidad</option>
                                        <option value="02">02 A su ritmo de aprendizaje</option>
                                        <option value="03">03 Apariencia física</option>
                                        <option value="04">04 Religión</option>
                                        <option value="05">05 A su pertenencia étnica</option>
                                        <option value="06">06 Características familiares</option>
                                        <option value="07">07 Otras</option>                               
                                </select>
                                
                                <div>
                                    <span className='label'>Otra Discriminación: </span>
                                    <input type="text"  className="form-control form-control-sm estilocampo" id="otra_discrimina" name="otra_discrimina" value={formData.otra_discrimina}   onChange={handleChange} />
                                </div>

                                <div>
                                    <div className='label'>Servicios de la Vivienda</div>  
                                    <select name="servicio_vivienda" id='servicio_vivienda' multiple style={{width:'280px', height:'150px'}} value={formData.otra_discrimina}   onChange={handleChange}>
                                        <option value="1">1 Agua</option>
                                        <option value="2">2 Alcantarillado</option>
                                        <option value="3">3 Gas domiciliario</option>
                                        <option value="4">4 Telefono</option>
                                        <option value="5">5 Internet</option>
                                        <option value="6">6 Energía</option>
                                        <option value="7">7 Recolección de basuras</option>
                                        <option value="8">8 Energía alternativa renovable</option>
                                    </select>
                                    <div>
                                        <span className='label'>¿Que desea estudiar?</span>
                                        <input type="text" value={formData.que_desa_estudiar}   onChange={handleChange}  className="form-control form-control-sm estilocampo" id="que_desa_estudiar" name="que_desa_estudiar" />
                                    </div>
                                </div>            


                                </div> {/* fin col-6 */}           

                                <div className='col-md-6'>
                                    
                                    <div>
                                        <span className='label'>No. Personas Hogar</span>
                                        <input type="text" style={{width:'80px'}} value={formData.num_pers_hogar}   onChange={handleChange} className="form-control form-control-sm estilocampo" id="num_pers_hogar" name="num_pers_hogar"  />
                                    </div>
                                    <div>
                                    <div className='label'>Tipo de Vivienda</div>  
                                    <select name="tipo_vivienda" id='tipo_vivienda' className="form-select form-select-sm estilocampo" value={formData.otra_discrimina}  onChange={handleChange}>
                                        <option value="1">01 - Casa</option>
                                        <option value="2">02 - Apartamento</option>
                                        <option value="3">03 - Cuartos</option>
                                        <option value="4">04 - Otros (Carpas, Albergues, etc)</option>
                                    </select>
                                    <div className='label'>Vivienda Propiedad</div>  
                                    <select name="vivienda_propiedad" className="form-select form-select-sm estilocampo" value={formData.otra_discrimina} onChange={handleChange}>
                                            <option value="1">01 Propia</option>
                                            <option value="2">02 Propia con credito</option>
                                            <option value="3">03 Arriendo</option>
                                            <option value="4">04 Usufructo</option>                           
                                    </select>
                                    <div className="form-check">
                                        <input name='educacion_preescolar'   value={formData.educacion_preescolar} className="form-check-input" type="checkbox" id="educacion_preescolar" onChange={handleChange}  />
                                        <label className="form-check-label" for="educacion_preescolar">Educación Preescolar</label>
                                    </div>                                    
                                    <div>
                                        <span className='label'>Años Preescolar</span>
                                        <input type="text" style={{width:'80px'}}  value={formData.num_anios_preescolar}   onChange={handleChange} className="form-control form-control-sm estilocampo" id="num_anios_preescolar" name="num_anios_preescolar" />
                                    </div>
                                    <div className="form-check">
                                        <input name='ret_est_sin_terminar'   value={formData.ret_est_sin_terminar} className="form-check-input" type="checkbox" id="ret_est_sin_terminar" onChange={handleChange}  />
                                        <label className="form-check-label" for="ret_est_sin_terminar">Retirado sin terminar año escolar</label>
                                    </div> 
                                    <div>
                                        <span className='label'>No. Meses por fuera</span>
                                        <input type="email" style={{width:'80px'}} value={formData.num_meses_por_fuera} onChange={handleChange} className="form-control form-control-sm estilocampo" id="num_meses_por_fuera" name="num_meses_por_fuera" />
                                    </div>    
                                    <div className="form-check">
                                        <input name='abandonos_temp' value={formData.abandonos_temp} className="form-check-input" type="checkbox" id="abandonos_temp" onChange={handleChange}  />
                                        <label className="form-check-label" for="abandonos_temp">Abandonos Temporales</label>
                                    </div>                                
                                    <div className="form-check">
                                        <input name='repetida_anio' value={formData.repetida_anio} className="form-check-input" type="checkbox" id="repetida_anio" onChange={handleChange}  />
                                        <label className="form-check-label" for="repetida_anio">Repetida de Año</label>
                                    </div>    
                                    <div>
                                        <span className='label'>No. Años Repetidos</span>
                                        <input type="text" style={{width:'80px'}}  value={formData.num_anios_repet}   onChange={handleChange} className="form-control form-control-sm estilocampo" id="num_anios_repet" name="num_anios_repet"  />
                                    </div>  
                                    <div className="form-check">
                                        <input name='rept_grado_actual'  value={formData.rept_grado_actual} className="form-check-input" type="checkbox" id="rept_grado_actual" onChange={handleChange}  />
                                        <label className="form-check-label" for="rept_grado_actual">Repetida Grado Actual</label>
                                    </div>         
                                    <div className='label'>Antece Disc Vida Aca</div>  
                                    <select name="antece_disc_vida" value={formData.antece_disc_vida} className="form-select form-select-sm estilocampo"  onChange={handleChange}>
                                            <option value="1">1 Llamados de atención</option>
                                            <option value="2">2 Suspensiones</option>
                                            <option value="3">3 Llamados de atención y suspensiones</option>
                                            <option value="4">4 No aplica</option>                     
                                    </select>                    
                                    <div className='label'>Asistencia Año Anterior</div>  
                                    <select name="asistencia_anio_anterior" value={formData.asistencia_anio_anterior} className="form-select form-select-sm estilocampo"  onChange={handleChange}>
                                            <option value="1">1 Alta</option>
                                            <option value="2">2 Media</option>
                                            <option value="3">3 Baja</option>
                                            <option value="4">4 No aplica</option>
                                    </select>       
                                    <div className="form-check">
                                        <input name='dificultades_aprendisaje' value={formData.dificultades_aprendisaje} className="form-check-input" type="checkbox" id="dificultades_aprendisaje" onChange={handleChange}  />
                                        <label className="form-check-label" for="dificultades_aprendisaje">Dificultades de Aprendisaje</label>
                                    </div>             
                                    <div className="form-check">
                                        <input name='vinculado_mod_educ_inicial' value={formData.vinculado_mod_educ_inicial} className="form-check-input" type="checkbox" id="vinculado_mod_educ_inicial" onChange={handleChange}  />
                                        <label className="form-check-label" for="vinculado_mod_educ_inicial">Vinculado Mod. Edu. Inicial</label>
                                    </div>             
                                </div>  
                                </div>{/*  fin col-6 */}
                        </div>

                </div> {/* fin card-body */}
            </div>
            </section>
    </>    
    
  )
}

export default HojaMatricula