import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { useParams } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getToken, getSede } from '../services/Usuario';
import { PostData } from '../services/PostData';
import config from '../config';

const Consolidados = () => {
    
    const { grado, grupo } = useParams();
     
    const [datoGrado, setdatoGrado] = useState([])
    const [Periodo, setPeriodo] = useState(0)
    const [PeriodoActual, setPeriodoActual] = useState(0)
    const [listaConsolidado, setlistaConsolidado] = useState([])
    const [listaAsignaturas, setlistaAsignaturas] = useState([])
    const [LoadingTabla, setLoadingTabla] = useState(false)

    const Url_Informes = config.API_URL_INFORMES;

    useEffect(() => {

        GetData('periodos/actual/', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setPeriodo(result.periodo)
                setPeriodoActual(result.periodo)
            }
        })
        GetData('grados/infogrado/'+grado+'/'+grupo, null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setdatoGrado(result)
            }
        })
       



  }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {               
    
                setLoadingTabla(true);

                
                
                if (grado && grupo && Periodo){
                    const data = {
                        cod_grado: grado,
                        id_grupo: grupo,
                        id_periodo: Periodo
                    };

                    setlistaAsignaturas([])
                    setlistaConsolidado([])
                    const consolidadoResult = await PostData('consolidados/consolidadoperiodoad', data);
                    if (!consolidadoResult.error) {
                        const datos = consolidadoResult.consolidado;
                        setLoadingTabla(false);
                        setlistaConsolidado(datos.filas);
                        setlistaAsignaturas(datos.columnas);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, [grado, grupo, Periodo]);
    
     
  function generarNombreCorto(asignatura) {
    
    let  palabras = asignatura.replace(/\s+e\s+/ig, ' ').replace(/\s+y\s+/ig, ' '); //se remueven la e o la y del medio
    
    palabras = asignatura.split(' ');

    if (palabras.length === 1){
        return asignatura.substring(0, 3).toUpperCase()
    }else{
        return palabras[0].substring(0, 3).toUpperCase() + palabras[1].substring(0, 1).toUpperCase()
    }
  
  }
  

  return (
    <>
        <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Consolidado</div>    
                
                <div className="card-body pb-0">

                          <div className='row'>
                            <div className='col-md-4' style={{marginTop: '25px'}}>
                                <span className='ml-4 label'>Grado:</span> <span className='texto'>{datoGrado.nom_grado} - 0{grupo}</span>  
                                <span className='ml-4 label'>N° Alumnos: </span> <span className='texto'>{listaConsolidado.length}</span>                                
                            </div> 
                            <div className='col-md-2'>
                                    <strong>Período: </strong>
                                    <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Periodo} onChange={(e) => setPeriodo(e.target.value)}>
                                    {Array.from({length: PeriodoActual}, (_, index) => index + 1).map((value) => (
                                        <option key={value} value={value}>Período {value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-md-5'>
                            <div className="btn-group mt-3" role="group">
                                <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                Seleccione un Consolidado
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <li><a className="dropdown-item" target="_blank"  href={Url_Informes + `consolidado_periodo.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Por Período</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={Url_Informes + `consolidados_nive.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Por Período y Nivelación</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={Url_Informes + `consolidados_puesto.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Por Período y Puesto</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={Url_Informes + `consolidados_sum.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Período Acumulado Total</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={Url_Informes + `consolidados_prom.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Promedio por Período</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={Url_Informes + `consolidados_excelpro.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Excel Calculo Promedio</a></li>
                                </ul>
                            </div>
                            </div>
                        </div>                               

                        {LoadingTabla && 
                        <div className="progress mt-1 mb-2">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }  

                        <table className="table table-striped table-hover mt-2">
                        <thead>
                        <tr>
                            {listaAsignaturas.map((mat, index) => (
                                    index > 2 ? (
                                                    <OverlayTrigger
                                                        key={index}
                                                        placement="top"
                                                        overlay={<Tooltip id={`tooltip-top`}>{mat.asig} - {mat.doc}</Tooltip>}
                                                    >
                                                        <th scope="col">{mat.abrev}</th>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <th key={index} scope="col">{mat.toUpperCase()}</th>
                                                )         
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                            {listaConsolidado.length > 0 && listaConsolidado.map((datos, index) => (
                           <tr key={index}>
                           {datos.map((nota, colIndex) => (
                               colIndex < datos.length - 1 ? (
                                   <td key={colIndex} style={{ textAlign: colIndex >= 2 ? 'center' : 'left' }}>
                                       {colIndex >= 3 && nota < 3 ? <span className='nota_perdida'>{nota}</span> : nota}
                                   </td>
                               ) : (
                                   <td key={colIndex} style={{ textAlign: 'center' }}>
                                       {nota >= 3 ? <span className='nota_perdida'>{nota}</span> : nota}
                                   </td>
                               )
                           ))}
                       </tr>
                       
                            ))}

                        </tbody>
                        </table>
                </div>
                
            </div>
        </section>

    </>
  )
}

export default Consolidados