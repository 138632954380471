import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'

const NuevoUsuario = () => {

  const [TipoUsuario, setTipoUsuario] = useState(null)  
  const [ListTipoUser, setListTipoUser] = useState([])  
  const [Password, setPassword] = useState("")  
  const [ConfirPassword, setConfirPassword] = useState("")  
  const [Email, setEmail] = useState("")  
  const [Nombres, setNombres] = useState("")  
  const [ErrorPassword, setErrorPassword] = useState(false)  
  


  useEffect(() => {
    GetData('usuarios/tiposusuarios', null).then ((result) =>{
        let responseJSON = result;

        if (responseJSON.error === false){          
            setListTipoUser(result.datos)
        }
    })

}, [])

  useEffect(() => {
      validarpassword();
  }, [Password, ConfirPassword])
  

  const GuardarUsuario = async () => {
    const datos = {
        id_usuarios: "0",
        nombre_usuario: Nombres,
        email_us: Email,
        password_us: Password,
        id_tipos_usuario: TipoUsuario
    };

    try {
        const result = await PostData('usuarios/save', datos);
        if (!result.error) {
            Swal.fire({
                title: 'Guardar',
                text: 'Guardado con éxito.',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            setNombres('')
            setEmail('')
            setConfirPassword('')
            setPassword('')
        } else {
            Swal.fire({
                title: 'Error al Guardar',
                text: result.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    } catch (error) {
        console.error('Error al procesar la solicitud:', error);
        // Aquí puedes mostrar un mensaje de error genérico al usuario si algo sale mal
        Swal.fire({
            title: 'Error',
            text: 'Hubo un error al intentar guardar los datos. Por favor, inténtalo de nuevo más tarde.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }
};


  const validarpassword = () => {
      if (Password){          
          if (Password !== ConfirPassword){
              setErrorPassword(true)
          }else{
              setErrorPassword(false)
          }
      }
  }

  return (
    <>
         <div className="pagetitle">
      <h1>Nuevo Usuario</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="index.html">Home</a></li>
          <li className="breadcrumb-item">Nuevo Usuario</li>
          
        </ol>
      </nav>
    </div>

    <section className="section">
      <div className="row">
        <div className="col-lg-6">

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Crear Usuario</h5>

              
              <form method="post" >
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label">Tipo Usuario</label>
                  <div className="col-sm-9">
                    <select className="form-select" aria-label="Default select example" value={TipoUsuario} onChange={(e) => setTipoUsuario(e.target.value)} >
                      <option defaultValue={null}>Seleccione</option>
                      {ListTipoUser.map(tipo => {
                            if (tipo.idTiposUsuario !== 6)
                                return <option key={tipo.idTiposUsuario} value={tipo.idTiposUsuario}>{tipo.DescripcionTipoUs}</option>
                        })}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="nombres" className="col-sm-3 col-form-label">Nombres y Apellidos</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" id="nombres" name="nombres" value={Nombres} onChange={(e) => setNombres(e.target.value)} />
                  </div>
                </div>



                <div className="row mb-3">
                  <label htmlFor="email" className="col-sm-3 col-form-label" >Email</label>
                  <div className="col-sm-9">
                    <input type="email" className="form-control" id="email" value={Email} onChange={(e) => setEmail(e.target.value)}  />
                  </div>
                </div>                
                                          
                <div className="row mb-3">
                  <label htmlFor="inputText" className="col-sm-3 col-form-label">Contraseña</label>
                  <div className="col-sm-9">
                    <input type="password" className="form-control" name="password" value={Password} onChange={(e) => setPassword(e.target.value)}  />
                    {ErrorPassword && <span class="campo-error"> <i class="bi bi-exclamation-diamond"></i> Contraseñas no son iguales</span>}      
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="inputText" className="col-sm-3 col-form-label">Confirmar Contraseña</label>
                  <div className="col-sm-9">
                        <input type="password" className="form-control" name="confirpassword" value={ConfirPassword} onChange={(e) => setConfirPassword(e.target.value)}  />

                  </div>
                </div>

                               
                <div className="row mb-3">
                  <div className="col-sm-9">
                    <button type="button" className="btn btn-primary" onClick={() => GuardarUsuario()} disabled={!Nombres || !Password || ErrorPassword || TipoUsuario === null }>Guardar</button>
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>

      </div>
    </section>
    </>
  )
}

export default NuevoUsuario