import config from "../config";

const URL_SERVICIOS = config.API_URL_PPAL;
export { URL_SERVICIOS };

const URL_INFORMES = config.API_URL_INFORMES;
export { URL_INFORMES };

//export const URL_INFORMES = "http://inscandelaria.com/$api/printer/";
//export const URL_SERVICIOS = "http://pruebas.colesper.com/api_res/index.php/";
//export const URL_SERVICIOS = "http://127.0.0.1/api_simon/index.php/";
//export const URL_INFORMES = "http://127.0.0.1/api_simon/api_res/printer/";

