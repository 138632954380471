import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData'

const PromTodosPeriodosGrado = () => {

    const [ListadoGrados, setListadoGrados] = useState([])  
    const [Periodo, setPeriodo] = useState([])  

    useEffect(() => {
        GetData('periodos/actual/', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setPeriodo(result.periodo)
            }
        })

        GetData('estadisticas/promediosporperiodo/1', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setListadoGrados(result.grados)
            }
        })
        
  }, [])


  return (
    <>
        <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Promedios por Grados y Períodos</div>                    

                <div className="card-body pb-0 mt-3">
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Grado</th>
                            <th scope="col">Periodo 1</th>
                            <th scope="col">Periodo 2</th>
                            <th scope="col">Periodo 3</th>
                            <th scope="col">Periodo 4</th>
                            <th scope="col" style={{width:'15%'}}>OP</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoGrados.map((grado, index) => 
                            <tr key={index}>
                                <td>{grado.nombre_grado} - 0{grado.grupo}</td>
                                {grado.promedios_periodos.map((periodo) => 
                                         <td>{periodo}</td>
                                    )}
                            
                                <td>    
                                                             
                                </td>
                            </tr>
                        )}    
                        </tbody>
                        </table>
                </div>
            </div>
        </section>
    </>
  )
}

export default PromTodosPeriodosGrado