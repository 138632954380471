import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import BotonTabla from '../components/BotonTabla'

function DirectoresGrupo() {

    const [IdAsignacion, setIdAsignacion] = useState(0) 
    const [ListaGados, setListaGados] = useState([]) 
    const [ListaGrupos, setListaGrupos] = useState([]) 
    const [ListaDocentes, setListaDocentes] = useState([]) 
    const [ListaDirectores, setListaDirectores] = useState([]) 
    const [Loading, setLoading] = useState(false)
    const [Grado, setGrado] = useState(null)
    const [Grupo, setGrupo] = useState(null)
    const [NombreGrado, setNombreGrado] = useState("")
    const [Docente, setDocente] = useState("")
 
    useEffect(() => {
        GetData('grados/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaGados(result.grados)
            }
        })
        GetData('grupos/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaGrupos(result.grupos)
            }
        })
        ListarDocentes();
       
        ListarCarga()
    }, []) 

    const ListarDocentes = () => {
        GetData('docentes/1', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaDocentes(result.docentes)
            }
        })

    }

    const ListarCarga = () => {
        
        setListaDirectores([])

            setLoading(true)
            GetData('docentes/listadirectores/', null).then ((result) =>{
                let responseJSON = result;
        
                if (responseJSON.error === false){
                    setListaDirectores(result.directores)
                    setLoading(false)
                    return true;
                }
            })

    }

    const Guardar = () => {

        // Verifica si Grado, Docente, Asignatura e IH son null o vacíos
        if (Grado === null || Grupo === null || Docente === null) {
            Swal.fire({
                title: 'Error al Guardar',
                text: "Debe llenar todos los parámetros para poder guardar",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return; // Detiene la ejecución si falta algún parámetro
        }

        // Verifica si el valor de IH no es un número
        
        const datos = {
            id_dir_grupo: IdAsignacion ? IdAsignacion : 0,
            id_grado: Grado,
            id_grupo: Grupo,
            id_docente: Docente,
        }
          
        PostData('docentes/savedirector', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                Swal.fire({
                    title: 'Cargar Guardado',
                    text: 'Guardado con exito.',
                    icon:"success",
                    confirmButtonText: 'Aceptar'
                  });
                  ListarCarga();
                  ListarDocentes();
             }else{
              Swal.fire({
                title: 'Error al Guardar',
                text: result.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              
          }
        })
    }

    const Editar = (dato) => {
          setIdAsignacion(dato.id_dir_grupo)
          setDocente(dato.id_docente)
          setGrado(dato.id_grado)
          setGrupo(dato.id_grupo)
    }

    const Eliminar = (dato) => {

          Swal.fire({
            title: '¿Estás seguro?',
            text: '¡De borrar al director de grupo!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then((result) => {
            if (result.isConfirmed) {
                // Realiza la acción de eliminación si el usuario confirma
               
                const data = {
                    id_grado : dato.id_grado,
                    id_grupo : dato.id_grupo,
                    id_docente : dato.id_docente
                }

                PostData('docentes/deletedirector/',data).then ((result) =>{

                    let responseJSON = result;
        
                    if (responseJSON.error === false){
                        Swal.fire({
                            title: 'Eliminado',
                            text: 'El Director ha sido eliminado correctamente',
                            icon: 'success'
                        });
                        ListarCarga();
                    }
                })
                // Muestra un mensaje de éxito después de eliminar
              
            }
        });
    }

    const Nuevo = () => {
        
        setIdAsignacion(0)
        setDocente("")
        setGrado("")
        setGrupo("")  
    }

  return (
    <>
      <div className="pagetitle">
      <h1>Directores Grupo</h1>
            <nav>
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                <li className="breadcrumb-item">Directores Grupo</li>
                
                </ol>
            </nav>
            </div>

            
            <section className='section dashboard'>
            <div class="card">
                
                <div className="card-body pb-0 mt-3">
                <div class="formulario">
                    <form method="post" >

                            <div className='row'>
                                    <div className='col-md-12'>
                                        {IdAsignacion > 0 ? <span class="badge bg-warning text-dark">Editando {IdAsignacion}</span>  : <span class="badge bg-info text-dark">Nuevo</span>}
                                    </div>
                                    <div className='col-md-2'>
                                        <strong>Grado: </strong>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grado} onChange={(e) => setGrado(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGados.map(tipo => {
                                                    return <option key={tipo.id_grados} value={tipo.id_grados}>{tipo.nombre_grado}</option>
                                            })}
                                        </select> 
                                      
                                    </div>    
                                    <div className='col-md-2'>
                                        <strong>Grupo: </strong>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grupo} onChange={(e) => setGrupo(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGrupos.map(tipo => {
                                                    return <option key={tipo.id_grupo} value={tipo.id_grupo}>{tipo.num_grupo}</option>
                                            })}
                                        </select> 
                                      
                                    </div>    
                                    <div className='col-md-3'>
                                        <strong>Docente: </strong>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Docente} onChange={(e) => setDocente(e.target.value)}>
                                            <option defaultValue="">Seleccionar</option>
                                            {ListaDocentes.map(doc => {
                                                return (
                                                    <option key={doc.id_docentes} value={doc.id_docentes}>
                                                        {doc.nombres_apellidos_doc} {doc.es_director === 'S' && '★'} 
                                                    </option>
                                                );
                                            })}
                                        </select>

                                    </div>
                                    <div className='col-md-2'>
                                        <button type="button" class="btn btn-primary mt-4 ml-2 btn-sm" onClick={Nuevo}>
                                                Nuevo
                                        </button>
                                        <button type="button" class="btn btn-success mt-4 ml-2  btn-sm" onClick={Guardar} disabled={Grado === null || Docente === null || Grupo===null }>
                                                Guardar
                                        </button>
                                    </div>
                                            
                               {/*  <div className='col-md-3 filter d-flex justify-content-end'>
                                    
                                </div> */}
                            </div>
                            <div class="alert alert-info alert-dismissible fade show mt-2" role="alert">
                                <i class="bi bi-info-circle me-1"></i>
                                Los docentes marcados con el símbolo ★ ya desempeñan el rol de director de grupo.
                            </div>
                            <hr />
                        <div className='row mt-1'>
                            
                            
                            <div className='col-md-3'>
                            
                            </div>                                
                            <div className='col-md-2'>
                                    
                            </div> 
                        </div>
                    </form>
                </div>
                <div className="card-body pb-0">
                    <h5 className="card-title">Directores de Grupos - Total: {ListaDirectores.length}
                    </h5>

                    <div className='row'>
                       
                    </div>
                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr key={0}>
                            <th scope="col">#</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Grupo</th>
                            <th scope="col">Docente</th>                            
                            <th scope="col">OP</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaDirectores.map((us, index) => 
                            <tr key={index}>
                                <th scope="row">{index+1}</th>
                                <td>{us.nombre_grado}</td>
                                <td>{us.num_grupo}</td>
                                <td>{us.nombres_apellidos_doc}</td>
                                <td>                                        
                                    <BotonTabla icono='lapiz.png' titulo='Modificar' evento={()=>Editar(us)} />
                                    <BotonTabla icono='borrar.png' titulo='Borrar' evento={()=>Eliminar(us)} />
                                </td>
                            </tr>
                        )}    
                        
                        </tbody>
                    </table>
               </div>
               </div>
            </div>
        </section>        
    </>
  )
}

export default DirectoresGrupo