import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <h1>404</h1>
        <h2>La pagina consultada no existe.</h2>
        <Link className="btn" to="/home/">Regregar al Home</Link>
        <img src="/assets/img/not-found.svg" className="img-fluid py-5" alt="Page Not Found" />
        <div className="credits">
          Designed by <a href="#">Innovación Digital</a>
        </div>
      </section>
  )
}

export default NotFound