import { getToken, getID } from './Usuario';

export function GetData(type){    

    const url_api = localStorage.getItem("URL_SERVICIOS");
    const campus = localStorage.getItem("selectedCampus");
    const token = getToken();
    const id = getID();
    
    
    return new Promise((resolve, reject) => {        
        fetch(url_api+type+'/'+id+'/'+token+'/'+campus)   
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
           reject(error);
        })
    })    
}