import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import BotonTabla from '../components/BotonTabla'
import Swal from 'sweetalert2'
import { PostData } from '../services/PostData'
import {validarEmail} from '../components/utils'

const ListaUsuarios = () => {   

   const [ListaTiposUS, setListaTiposUS] = useState([]) 
   const [TiposUsuario, setTiposUsuario] = useState('Todos') 
   const [ListaUsuarios, setListaUsuarios] = useState([]) 
   const [Loading, setLoading] = useState(true)

   const [LoadingForm, setLoadingForm] = useState(false)
   const [ModIdUsuario, setModIdUsuario] = useState(0)
   const [EstadoUsuario, setEstadoUsuario] = useState(null)
   const [TipoUsuario, setTipoUsuario] = useState(null)     
   const [Password, setPassword] = useState("")  
   const [ConfirPassword, setConfirPassword] = useState("")  
   const [Email, setEmail] = useState("")  
   const [Nombres, setNombres] = useState("")  
   const [ErrorPassword, setErrorPassword] = useState(false)  
   const [ErrorEmail, setErrorEmail] = useState(false)  
   

   useEffect(() => {
    GetData('usuarios/tiposusuarios', null).then ((result) =>{
        let responseJSON = result;

        if (responseJSON.error === false){
            setListaTiposUS(result.datos)
        }
    })

    }, []) 

    useEffect(() => {
        validarpassword();
    }, [Password, ConfirPassword])

    useEffect(() => {
        setErrorEmail(!validarEmail(Email));
    }, [Email])

   useEffect(() => {
    setLoading(true)
    setListaUsuarios([])
    GetData('usuarios/'+TiposUsuario, null).then ((result) =>{
        let responseJSON = result;

        if (responseJSON.error === false){
            setListaUsuarios(result.datos)
            setLoading(false)
            return true;
        }
    })
}, [TiposUsuario])      

    const ListarUsuarios = () => {
        setLoading(true)
        GetData('usuarios/'+TiposUsuario, null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaUsuarios(result.datos)
                setLoading(false)
                return true;
            }
        })
    }

    const Modificar = (dato) => {
        
                setModIdUsuario(dato.id_usuarios)
                setNombres(dato.nombre_usuario)
                setEmail(dato.email_us)
                setEstadoUsuario(dato.estado_us)
                setTipoUsuario(dato.id_tipos_usuario)
         

        const modal = document.getElementById('modalD');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
    }

    const validarpassword = () => {
        if (Password){          
            if (Password !== ConfirPassword){
                setErrorPassword(true)
            }else{
                setErrorPassword(false)
            }
        }else{
            setErrorPassword(false)
        }
    }

    const GuardarUsuario = async () => {
        const datos = {
            id_usuarios: ModIdUsuario,
            nombre_usuario: Nombres,
            email_us: Email,
            password_us: Password,
            id_tipos_usuario: TipoUsuario
        };
    
        try {
            const result = await PostData('usuarios/save', datos);
            if (!result.error) {
                Swal.fire({
                    title: 'Guardar',
                    text: 'Guardado con éxito.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                });
                setModIdUsuario(result.id_usuario)
                ListarUsuarios()
            } else {
                Swal.fire({
                    title: 'Error al Guardar',
                    text: result.mensaje,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        } catch (error) {
            console.error('Error al procesar la solicitud:', error);
            // Aquí puedes mostrar un mensaje de error genérico al usuario si algo sale mal
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error al intentar guardar los datos. Por favor, inténtalo de nuevo más tarde.',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const NuevoUsuario =  () => {
        setModIdUsuario("0")
        setNombres("")
        setEmail("")
        setEstadoUsuario(0)
        setTipoUsuario("null")
        setPassword("")
        setConfirPassword("")

        const modal = document.getElementById('modalD');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
    }

    const Eliminar = (id_usuarios, nombre) => {

        Swal.fire({
          title: '¿Estás seguro?',
          text: '¡No podrás revertir esto! ¿Estas seguro de eliminar a: ' + nombre + '?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminarlo'
      }).then((result) => {
          if (result.isConfirmed) {
             
              const datos = {
                    id_usuarios
              }  
              PostData('usuarios/delete/' + id_usuarios, datos).then ((result) =>{

                  let responseJSON = result;
      
                  if (responseJSON.error === false){
                      Swal.fire({
                          title: 'Eliminado',
                          text: 'El usuario se ha sido eliminado correctamente',
                          icon: 'success'
                      });
                      ListarUsuarios();
                  }
              })
              // Muestra un mensaje de éxito después de eliminar
            
          }
      });
      
  }
    

  return (
    <>
        <section className='section dashboard'>
            <div class="card">
                    <div class="filter">
                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li class="dropdown-header text-start">
                        <h6>Filtrar</h6>
                        </li>
                        <li><a class="dropdown-item" href="#" onClick={()=>setTiposUsuario(null)}>Todos</a></li>
                            {ListaTiposUS.map(tipo => <li><a class="dropdown-item" href="#" onClick={()=>setTiposUsuario(tipo.id_tipos_persona)}>{tipo.nombre_tipos_p}</a></li>)}
                    </ul>
                    </div>
                <div className="card-body pb-0">
                    <h5 className="card-title">Listado Usuarios  
                    </h5>

                    <div className='row'>
                        <div className='col-md-3'>
                            Filtrar: 
                            <select className="form-select sm" aria-label="Default select example" value={TiposUsuario} onChange={(e) => setTiposUsuario(e.target.value)} >
                            <option defaultValue={null}>Todos</option>
                            {ListaTiposUS.map(tipo => {
                                    if (tipo.idTiposUsuario !== 6)
                                        return <option key={tipo.idTiposUsuario} value={tipo.idTiposUsuario}>{tipo.DescripcionTipoUs}</option>
                                })}
                            </select> 
                        </div>    
                        <div className='col-md-2'>
                                <button type="button" className="btn btn-primary ml-2 btn-sm" style={{marginTop: '25px'}} onClick={NuevoUsuario}  >
                                        <i className="bi bi-person-plus"></i> Nueva Usuario
                                </button>
                            </div> 
                    </div>
                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <table class="table table-striped  mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombres</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Ultima Sesión</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Op</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaUsuarios.map((us, index) => 
                        <tr>
                            <th scope="row">{index+1}</th>
                            <td> {us.nombre_usuario}</td>
                            <td>{us.email_us}</td>
                            <td>{us.icono && <img src={`/assets/img/${us.icono}`} width={25}  />} {us.DescripcionTipoUs}</td>
                            <td>{us.ultima_fecha_sesion}</td>
                            <td>{us.estado_us === "1" ? <span class="badge bg-success">Activo</span> : <span class="badge bg-secondary">Inactivo</span>}</td>
                            <td>
                                <BotonTabla icono='lapiz.png' titulo='Modificar Usuarios' evento={()=>Modificar(us)} />
                                <BotonTabla icono='borrar.png' titulo='Borrar Matricula' evento={()=>Eliminar(us.id_usuarios , us.nombre_usuario)} />
                            </td>
                        </tr>
                        )}    
                        
                        </tbody>
                    </table>
               </div>
            </div>
        </section>            

        <div className="modal fade" id="modalD" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-md">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> Editar Usuario ID° {ModIdUsuario}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {LoadingForm && 
                        <div className="progress mt-3">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }     
                        <div className='alert alert-warning alert-dismissible fade show'>
                            Si no desea cambiar la contraseña, por favor deje los campos 'Contraseña' y 'Confirmar contraseña' en blanco.    
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <span className='label'>Tipo de Usuario: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example"  value={TipoUsuario} onChange={(e) => setTipoUsuario(e.target.value)} >
                                    <option defaultValue={null}>Seleccione</option>
                                    {ListaTiposUS.map(tipo => {
                                            if (tipo.idTiposUsuario !== 6)
                                                return <option key={tipo.idTiposUsuario} value={tipo.idTiposUsuario}>{tipo.DescripcionTipoUs}</option>
                                        })}
                                </select>
                            </div>   
                            <div className='col-md-6'>
                                <span className='label'>Estado: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example"  value={EstadoUsuario} onChange={(e) => setEstadoUsuario(e.target.value)} >
                                    <option defaultValue={null}>Seleccione</option>
                                    <option value="1">Activo</option>
                                    <option value="0">Inactivo</option>
                                    
                                </select>
                            </div>   
                            <div className='col-md-12'>
                                <span className='label'>Nombres y Apellidos: </span>
                                <input type="text" 
                                    className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Nombres} onChange={(e) => setNombres(e.target.value)} />
                            </div>     
                            <div className='col-md-12'>
                                <span className='label'>E-Mail: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Email} onChange={(e) => setEmail(e.target.value)} />
                                {ErrorEmail && <span class="campo-error"> <i class="bi bi-exclamation-diamond"></i> El E-mail digitano no es válido</span>}
                            </div>     
                            <div className='col-md-6'>
                                <span className='label'>Contraseña: </span>
                                <input type="password" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Password} onChange={(e) => setPassword(e.target.value)} />
                                {ErrorPassword && <span class="campo-error"> <i class="bi bi-exclamation-diamond"></i> Contraseñas no son iguales</span>} 
                            </div>     
                            <div className='col-md-6'>
                                <span className='label'>Confirmar Contraseña: </span>
                                <input type="password" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={ConfirPassword} onChange={(e) => setConfirPassword(e.target.value)} />
                            </div>     
                             
                        </div>
                      </div>
                        <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
                                <button type="button" className="btn btn-primary btn-sm" onClick={()=> GuardarUsuario()} disabled={Nombres === "" || Email === "" || ErrorEmail || TipoUsuario === null || ErrorPassword } data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Guardar Usuario"><i className="bi bi-clipboard-plus"></i> Guardar</button>                      
                         </div>
                 </div>
            </div>
        </div>                        
    </>
  )
}

export default ListaUsuarios