// config.js
const config = {
    LOGO: '/asset/img/logo.png',
    VERSION_APP: 1.5,
    NOMBRE_APP: 'CNotas',
    API_URL_PPAL: 'https://iesimonbolivar.com/api_simon_sedes/index.php/',
    API_URL_INFORMES: 'https://iesimonbolivar.com/api_simon_sedes/informe/',
    API_URL_FOTOS: 'https://iesimonbolivar.com/api_simon_sedes/uploads/fotosperfil/',
    API_URL_PATH: 'http://iesimonbolivar.com/',
    EMPRESA: 'Innovacion Digital',
    URL_EMPRESA: '#'
  };
  
  export default config;
  