import React, { useEffect, useState } from 'react'
import Grado from './Grado'
import { GetData } from '../../services/GetData'


const Grados = ({Periodo}) => {
  
    const [ListaGrados, setListaGrados] = useState([])
    const listaClasesColores = ['titulo_grado_naranja', 'titulo_grado_verde', 'titulo_grado_fuxia'];
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        GetData('asignaciones/todosgrados/').then((result) => {
                    setListaGrados(result.grados)
                    setLoading(false)
        })
    }, [])
    
    const SelectColor = (num_grado) => {           
          if (num_grado < 6)
              return listaClasesColores[0];
            
          if (num_grado >= 6 && num_grado < 10)
              return listaClasesColores[1];
          if (num_grado >= 10)
              return listaClasesColores[2];
    }

  return (
            <>
            {Loading && 
                 <div className="progress mt-3">
                     <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
            </div>      
                    }    
            {ListaGrados.map((grado, index) => {
                return (
                        <Grado key={`${grado.id_grado}-${index}`} Periodo={Periodo} index={index} grado={grado} color={SelectColor(grado.num_grado)} />
                    )
                })}            

            </>
  )
}

export default Grados