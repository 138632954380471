import { Link, NavLink } from "react-router-dom"
import { getToken, getSede, getTipoUser, Usuario } from '../services/Usuario';

const SideBar = () => {

    const usuario = Usuario();
    const tipousario = parseInt(usuario.id_tipos_usuario)

  return (
    <aside id="sidebar" className="sidebar">

        <ul className="sidebar-nav" id="sidebar-nav">

        <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/home/principal" activeClassName="active">
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
            </NavLink>
        </li>
        {(tipousario === 0 || tipousario === 1 || tipousario === 4) && 
        <li className="nav-item">
            <Link className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
            <i className="bi bi-gear"></i><span>Administrativo</span><i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">     
            {tipousario === 0 &&       
                <li>
                    <NavLink to={"/home/listadousuarios"} className="nav-link collapsed">
                    <i className="bi bi-circle"></i><span>Administra de Usuarios</span>
                    </NavLink>
                </li>
                }
               
                <li className="nav-item">            
                    <NavLink to={"/home/cargaacademica"} className="nav-link collapsed" activeClassName="active">
                    <i className="bi bi-book"></i>
                    <span>Carga Académica</span>
                    </NavLink>
                </li>    
                <li className="nav-item">            
                    <NavLink to={"/home/desempenios"} className="nav-link collapsed" activeClassName="active">
                    <i className="bi bi-graph-up"></i>
                    <span>Desempeños</span>
                    </NavLink>
                </li>       
                <li className="nav-item">            
                    <NavLink to={"/home/docentes"} className="nav-link collapsed">
                    <i className="bi bi-person-check"></i>
                    <span>Docentes</span>
                    </NavLink>
                </li>       
                <li className="nav-item">            
                    <NavLink to={"/home/directoresgrupo"} className="nav-link collapsed">
                    <i className="bi bi-people"></i>
                    <span>Directores de Grupo</span>
                    </NavLink>
                </li>       
                <li className="nav-item">            
                    <NavLink to={"/home/periodos"} className="nav-link collapsed">
                    <i className="bi bi-people"></i>
                    <span>Configurar Períodos</span>
                    </NavLink>
                </li>       
                {/* <li className="nav-item">            
                    <NavLink to={"/home/modcalificaciones/0/0/0/1"} className="nav-link collapsed">
                    <i className="bi bi-people"></i>
                    <span>Modificar Calificaciones</span>
                    </NavLink>
                </li>        */}
            </ul>
        </li>
        }
       
       <li className="nav-item ">            
                    <NavLink to={"/home/matriculas"} className="nav-link collapsed" activeClassName="active">
                    <i className="bi bi-person-plus"></i>
                    <span>Matriculas</span>
                    </NavLink>
                </li>       
        <li className="nav-item">
            <Link className="nav-link collapsed" data-bs-target="#menu-estadis" data-bs-toggle="collapse" href="#">
            <i className="bi bi-graph-up"></i><span>Estadísticas</span><i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul id="menu-estadis" className="nav-content collapse " data-bs-parent="#menu-estadis">
                <li>
                    <NavLink to={"/home/procdesempgrados"} className="nav-link collapsed">
                    <i className="bi bi-circle"></i><span>Proc. Desempeños Grados</span>
                    </NavLink>
                </li>            
                <li>
                    <NavLink to={"/home/promediostodosgrados"} className="nav-link collapsed">
                    <i className="bi bi-circle"></i><span>Promedio de Grados</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/home/estadisgradoperiodo"} className="nav-link collapsed">
                    <i className="bi bi-circle"></i><span>Estad. Desempeño por Grado</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/home/desempdocentesperiodo"} className="nav-link collapsed">
                    <i className="bi bi-circle"></i><span>Desemp. Docentes Período</span>
                    </NavLink>
                </li>
            </ul>
        </li>
       
        <li className="nav-item">
            <Link className="nav-link collapsed" data-bs-target="#menu-informe" data-bs-toggle="collapse" href="#">
            <i className="bi bi-menu-button-wide"></i><span>Informes</span><i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul id="menu-informe" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                <li>
                    <NavLink to={"/home/rankinperiodo"} className="nav-link collapsed">
                    <i className="bi bi-circle"></i><span>Rankin de Alumnos Período</span>
                    </NavLink>
                
                </li>
                <li>
                    <a href={`https://iesimonbolivar.com/api_simon_sedes/informe/lisadoalumnosdatostodos.php?token=${getToken()}&db=${getSede()}`} className="nav-link collapsed">
                    <i className="bi bi-circle"></i><span>Descargar Listado de Alumnos</span>
                    </a>
                </li>            
                <li>
                    <NavLink to={"/home/alummas2peridasper"} className="nav-link collapsed">
                    <i className="bi bi-circle"></i><span>Alumno más de 2 Perdidas</span>
                    </NavLink>
                </li>            
           
            </ul>
        </li>

        </ul>

    </aside>
  )
}

export default SideBar