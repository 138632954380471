import React, { useEffect, useState } from 'react'
import { colorNota } from './utils'
import InputIna from './InputIna'
import { InputNotaIndicador2 } from './InputNotaIndicador2'


const NotaFilaIndiAlumno = ({index, alum, grado, grupo, idasignatura}) => {

    const [PromedioEst, setPromedioEst] = useState(0);
    const [Nota1, setNota1] = useState(alum.nota_indic1);
    const [Nota2, setNota2] = useState(alum.nota_indic2);
    const [Nota3, setNota3] = useState(alum.nota_indic3);
    const [Nota4, setNota4] = useState(alum.nota_indic4);

    useEffect(() => {
        const notas = [Nota1, Nota2, Nota3, Nota4].filter(nota => nota !== null && nota !== undefined && nota !== '');
        
        if (notas.length > 0) {
            const prom = notas.reduce((acc, curr) => acc + parseFloat(curr), 0) / notas.length;
            setPromedioEst(prom.toFixed(1));
        } else {
            setPromedioEst(0); // Si no hay notas, el promedio es 0 o lo que prefieras
        }

    }, [Nota1, Nota2, Nota3, Nota4]);
    
    const onChangeNotaAct = (num_nota, nota) => {
            switch (num_nota) {
                case 1:
                    setNota1(nota);
                    break;
                case 2:
                    setNota2(nota);
                    break;
                case 3:
                    setNota3(nota);
                    break;
                case 4:
                    setNota4(nota);
                    break;
            
                default:
                    break;
            }
    }

   return (
      <>
          <tr key={alum.id_matricula}>
              <td>{index + 1}</td>
              <td>{alum.id_matricula}</td>
              <td>{alum.nombres_apellidos_al}</td>
              <td style={{ width: '4%' }}>
                  <InputIna
                      grado={grado}
                      grupo={grupo}
                      valor={alum.ina}
                      id_matricula={alum.id_matricula}
                      id_asignatura={idasignatura}
                  />
              </td>              
                <td width="5%" key={alum.id_matricula}>
                <InputNotaIndicador2
                    num_nota={1}
                    id_matricula={alum.id_matricula}
                    nota={Nota1}
                    grado={grado}
                    grupo={grupo}
                    idasignatura={idasignatura}
                    onChangeNotaAct={onChangeNotaAct}
                />
                </td>
                <td width="5%" key={alum.id_matricula}>
                <InputNotaIndicador2
                    num_nota={2}
                    id_matricula={alum.id_matricula}
                    nota={Nota2}
                    grado={grado}
                    grupo={grupo}
                    idasignatura={idasignatura}
                    onChangeNotaAct={onChangeNotaAct}
                />
                </td>
                <td width="5%" key={alum.id_matricula}>
                <InputNotaIndicador2
                    num_nota={3}
                    id_matricula={alum.id_matricula}
                    nota={Nota3}
                    grado={grado}
                    grupo={grupo}
                    idasignatura={idasignatura}
                    onChangeNotaAct={onChangeNotaAct}
                />
                </td>
                <td width="5%" key={alum.id_matricula}>
                <InputNotaIndicador2
                    num_nota={4}
                    id_matricula={alum.id_matricula}
                    nota={Nota4}
                    grado={grado}
                    grupo={grupo}
                    idasignatura={idasignatura}
                    onChangeNotaAct={onChangeNotaAct}
                />
                </td>
                <td>
                    <span className={`badge ${colorNota(PromedioEst)} celda-nota-ind-pro`}>
                            {PromedioEst}
                        </span>
                </td>
            
          </tr>
      </>
  );
}

export default NotaFilaIndiAlumno