import React, { useEffect, useState } from 'react'
import PeriodoComponent from '../components/PeriodoComponent'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import { getSede } from '../services/Usuario'

const ConfigurarPeriodos = () => {

    const [ListaPeriodos, setListaPeriodos] = useState([])
    const [CambiosSedes, setCambiosSedes] = useState(false)
    const [PeriodoActual, setPeriodoActual] = useState(0)

   useEffect(() => {
            GetData('periodos/todos', null).then ((result) =>{
                let responseJSON = result;

                if (responseJSON.error === false){
                    setListaPeriodos(result.periodos)
                }
            })
            GetData('periodos/actual', null).then ((result) =>{
                let responseJSON = result;
    
                if (responseJSON.error === false){
                    setPeriodoActual(result.periodo)
                }
            })

   }, [])
    

  const onPeriodoChange = (numero, fechaInicial, fechaFinal, fechaInicialNivelacion, fechaFinalNivelacion) => {
       
        const datos = {
            id_periodo: numero, 
            fecha_inicio: fechaInicial, 
            fecha_cierre: fechaFinal, 
            fecha_inicio_niv: fechaInicialNivelacion, 
            fecha_cierre_niv: fechaFinalNivelacion,
            cambios_sedes: CambiosSedes
        }

        PostData('periodos/saveadmin', datos).then((result)=> {
              if (result.error === false){
                Swal.fire({
                    title: 'Guardar Período',
                    text: result.mensaje,
                    icon:"success",
                    confirmButtonText: 'Aceptar'
                  });                  

              }else{
                Swal.fire({
                    title: 'Error - Guardar Período',
                    text: result.mensaje,
                    icon:"error",
                    confirmButtonText: 'Aceptar'
                  });    
              }
        })
  } 

  return (
    <>
         <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Configurar Períodos</div>   

                <div className="card-body pb-0">
                <div className='row mt-2'>
                <div className='col-md-12 alert alert-info alert-dismissible fade show'>

                    Por favor, asegúrate de ingresar las fechas de inicio y fin para cada período de manera precisa. 
                    Ten en cuenta que el período se considerará cerrado a partir de las 12 de la noche en la fecha especificada como cierre. 
                    Esto garantizará que todas las actividades y procesos planificados dentro del período se completen según lo programado.
                </div>
                {getSede() === 'default' && 
                <div className='row'>
                    <div className='col-md-4'>
                        <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={CambiosSedes} onChange={(e)=> setCambiosSedes(!CambiosSedes)} />
                        <label class="form-check-label text-danger" for="flexSwitchCheckChecked">Aplicar cambios en la sedes</label>
                        </div>
                    </div>
                </div>
                }
            </div>


                </div>

            </div>

            {ListaPeriodos.map((periodo =>
                <PeriodoComponent key={periodo.id_periodo}  periodo={periodo} periodoNumber={periodo.num_periodo} PeriodoActual={PeriodoActual} onPeriodoChange={onPeriodoChange} />
            ))}

           
          </section>
    </>
  )
}

export default ConfigurarPeriodos